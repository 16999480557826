<template>
  <div class="h-100">
    <query-form  class="content_block" label-width="120px" :keepOpenedRow="2" @search="handleSearch" @reset="handleReset">
      <el-form-item label="工单状态：" :span="24">
        <check-tag v-model="query.status" @change="handleSearch">
          <check-tag-item :label="`${i.name}(${i.count})`" :value="i.value" :key="i.value" v-for="i in OrderStatusEnumList"></check-tag-item>
        </check-tag>
      </el-form-item>
       <el-form-item label="租户名称：" :span="6">
        <el-input  v-model.trim="query.tenantName" placeholder="请输入租户名称"></el-input>
      </el-form-item>
      <el-form-item label="工单编号：" :span="6">
        <el-input v-model.trim="query.code" placeholder="请输入工单编号"></el-input>
      </el-form-item>
      <el-form-item label="客户名称：" :span="6">
        <el-input v-model.trim="query.customerName" placeholder="请输入客户名称"></el-input>
      </el-form-item>
      <el-form-item label="渠道工单编号：" :span="6" label-width="120px">
        <el-input v-model.trim="query.channelWorkOrderCode" placeholder="请输入渠道工单编号"></el-input>
      </el-form-item>
      <el-form-item label="创建日期：" class="JZCategoryFormType" :span="6">
        <el-date-picker
          @change='handleSearch'
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
          v-model.trim="query.createTime"
          :default-time="['00:00:00', '23:59:59']">
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="客户付费情况：">
        <el-select v-model="query.customerPayStatus" @change="handleSearch">
          <el-option label="全部" :value="null"></el-option>
          <el-option label="未支付" value="1"></el-option>
          <el-option label="已支付" value="2"></el-option>
        </el-select>
      </el-form-item>
    </query-form>
    <table-with-pagination
      :tableData="orderList"
      ref="orderTable"
      class="content_block"
      :checkbox-config="{reserve:true}"
      id="orderTable"
      show-right-toolbar
      :columnList="columnList"
      :pagination.sync="pagination"
      :sortInfo.sync="createTimeSortInfo"
      @current-change="getTargetLists"
      @sort-change="getTargetLists"
    />
  </div>
</template>
<script>
import * as dayjs from 'dayjs';
import { getQueryWorkOrderPage, getQueryWorkOrderCount} from '@/api/report';
import { mapGetters } from 'vuex';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import { CheckTag, CheckTagItem } from '@/components/CheckTag';

export default {
  name: 'ORDER_INDEX',
  mixins: [
    TablePaginationMixin,
  ],
  components: {
    CheckTag,
    CheckTagItem,
  },
  data () {
    return {
      orderList: [],
      OrderTypeEnumList: [
        {
          value: null,
          name: '全部工单',
        },
        {
          value: 'REPAIR_WORK_ORDER',
          name: '维修工单',
        },
        {
          value: 'MAINTENANCE_WORK_ORDER',
          name: '保养工单',
        },
      ],
      columnList: [
        {
          tooltip: true,
          label: '工单编号',
          minWidth: '140px',
          prop: 'code',
          fixed: 'left',
          render: (h, {row})=>{
            if (this.actionPermissionMap['saas_order_detail:query']) {
              return <span class='text-primary cursor-pointer' onClick={this.handleActionClick.bind(this, row)}>{row.code}</span>;
            } else {
              return <span >{row.code}</span>;
            }
          },
        },
        {
          tooltip: true,
          label: '租户名称',
          minWidth: '200px',
          prop: 'tenantName',
        },
        {
          tooltip: true,
          label: '是否正式租户',
          minWidth: '120px',
          prop: 'tenantIsFormalMsg',
          format: val=>val?.label,
        },

        {
          tooltip: true,
          label: '渠道工单编号',
          minWidth: '180px',
          prop: 'channelWorkOrderCode',
        },
        {
          tooltip: true,
          label: '工单状态',
          minWidth: '100px',
          prop: 'status',
          render: (h, {row})=>{
            return row.statusMsg?.label;
          },
        },
        {
          tooltip: true,
          label: '工单类型',
          minWidth: '100px',
          prop: 'typeMsg',
          format: val =>val?.name,
        },
        {
          tooltip: true,
          label: '客户付费情况',
          minWidth: '100px',
          prop: 'customerPayStatusMsg',
          format: val=>val?.label,
        },
        {
          tooltip: true,
          label: '客户名称',
          minWidth: '150px',
          prop: 'customerMsg',
          format: val=>val?.name,
        },
        {
          tooltip: true,
          label: '所在地区',
          minWidth: '150px',
          prop: 'customerAreaName',
          render: (h, {row})=>{
            return <span>{row.customerMsg?.provinceName || ''}{row.customerMsg?.cityName || ''}{row.customerMsg?.areaName || '--'}</span>;
          },
        },
        {
          tooltip: true,
          label: '详细地址',
          minWidth: '150px',
          prop: 'customerMsg',
          format: val=>val?.address,
        },
        {
          tooltip: true,
          label: '联系人',
          minWidth: '150px',
          prop: 'contactPersonMsg',
          format: val=>val?.name,
        },
        {
          tooltip: true,
          label: '联系电话',
          minWidth: '150px',
          prop: 'contactPersonMsg',
          format: val=>val?.phone,
        },
        {
          tooltip: true,
          label: '联系地址',
          minWidth: '200px',
          prop: 'contactPersonAddressMsg',
          format: val=>val?.address,
        },
        {
          tooltip: true,
          label: '创建人',
          minWidth: '100px',
          prop: 'createMsg',
          format: val=>val?.name,
        },
        {
          tooltip: true,
          label: '创建时间',
          minWidth: '160px',
          prop: 'createTime',
          sortable: true,
          format: this.$options.filters['date-format'],
        },
        {
          tooltip: true,
          label: '期望到场时间',
          minWidth: '180px',
          prop: 'planFinishTime',
          render: (h, {row: orderDetail})=>{
            let dateFormatFilter = (val) =>{
              if (!val) return '';
              if (!isNaN(val)) {
                val = +val;
              }
              return dayjs(val).format('YYYY-MM-DD');
            };
            let timeFormatFilter = (val)=> {
              if (!val) return '';
              if (!isNaN(val)) {
                val = +val;
              }
              return dayjs(val).format('HH:mm');
            };
            if (!orderDetail.expectedStartTime) {
              return '--';
            }
            return <span>{dateFormatFilter(orderDetail.expectedStartTime) || ''} {timeFormatFilter(orderDetail.expectedStartTime) || ''}~{timeFormatFilter(orderDetail.expectedEndTime) || ''}</span>;
          },
        },
        {
          tooltip: true,
          label: '派单人',
          minWidth: '100px',
          prop: 'dispatchMsg',
          format: val=>val?.name,
        },
        {
          tooltip: true,
          label: '派单时间',
          minWidth: '160px',
          prop: 'dispatchTime',
          format: this.$options.filters['date-format'],
        },
        {
          tooltip: true,
          label: '负责人',
          minWidth: '100px',
          prop: 'principalMsg',
          format: val=>val?.name,
        },
        {
          tooltip: true,
          label: '接单时间',
          minWidth: '160px',
          prop: 'orderTime',
          format: this.$options.filters['date-format'],
        },
        {
          tooltip: true,
          label: '签到时间',
          minWidth: '160px',
          prop: 'attendanceTime',
          format: this.$options.filters['date-format'],
        },
        {
          tooltip: true,
          label: '工程师签字时间',
          minWidth: '160px',
          prop: 'signTime',
          format: this.$options.filters['date-format'],
        },
        {
          tooltip: true,
          label: '客户签字时间',
          minWidth: '160px',
          prop: 'signTime',
          format: this.$options.filters['date-format'],
        },
        {
          tooltip: true,
          label: '关单时间',
          minWidth: '160px',
          prop: 'closeTime',
          format: this.$options.filters['date-format'],
        },
        {
          tooltip: true,
          label: '取消时间',
          minWidth: '160px',
          prop: 'cancelTime',
          format: this.$options.filters['date-format'],
        },
      ],
      dialogVisible: false,
      assignedUserId: '',
      query: {
        status: null,
        type: null,
        code: '',
        contactPersonName: '',
        customerName: '',
        principalName: '',
        tenantName: '',
        createTime: null,
        channelWorkOrderCode: '',
        customerPayStatus: null,
      },
      OrderStatusEnumList: [
        {
          name: '全部',
          value: null,
          count: 0,
        },
        {
          value: 'TO_BE_ASSIGNED',
          name: '待指派',
          count: 0,
        },
        {
          value: 'ASSIGNED',
          name: '已指派',
          count: 0,
        },
        {
          value: 'CANCELLED',
          name: '已取消',
          count: 0,
        },
        {
          value: 'ORDER_RECEIVED',
          name: '已接单',
          count: 0,
        },
        {
          value: 'IN_MAINTENANCE',
          name: '维修中',
          count: 0,
        },
        {
          value: 'SIGNED',
          name: '已签字',
          count: 0,
        },
        {
          value: 'COMPLETED',
          name: '已完成',
          count: 0,
        },
        {
          value: 'BE_EVALUATED',
          name: '待评价',
          count: 0,
        },
        {
          value: 'EVALUATED',
          name: '已评价',
          count: 0,
        },
      ],
    };
  },
  created () {
    this.handleSearch();
  },
  methods: {
    getSummary () {
      getQueryWorkOrderCount({}).then(tableInfo => {
        let orderSummary = tableInfo.body;
        this.OrderStatusEnumList = [
          {name: '全部', value: null, count: orderSummary.total || 0},
          {name: '待指派', value: 'TO_BE_ASSIGNED', count: orderSummary.toBeAssigned || 0},
          {name: '已指派', value: 'ASSIGNED', count: orderSummary.assigned || 0},
          {name: '已取消', value: 'CANCELLED', count: orderSummary.cancelled || 0},
          {name: '已接单', value: 'ORDER_RECEIVED', count: orderSummary.orderReceived || 0},
          {name: '维修中', value: 'IN_MAINTENANCE', count: orderSummary.inMaintenance || 0},
          {name: '已签字', value: 'SIGNED', count: orderSummary.signed || 0},
          {name: '已完成', value: 'COMPLETED', count: orderSummary.completed || 0},
          {name: '待评价', value: 'BE_EVALUATED', count: orderSummary.beEvaluated || 0},
          {name: '已评价', value: 'EVALUATED', count: orderSummary.evaluated || 0},
        ];
      });
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTargetLists();
      this.getSummary();
    },
    handleReset () {
      this.query = {
        status: null,
        type: null,
        code: '',
        contactPersonName: '',
        customerName: '',
        principalName: '',
        tenantName: '',
        channelWorkOrderCode: '',
        createTime: null,
        customerPayStatus: null,
      };
      this.handleSearch();
    },
    getTargetLists () {
      let workOrderStatus;
      let workOrderEvalStatus;
      if (['BE_EVALUATED', 'EVALUATED'].includes(this.query.status)) {
        workOrderEvalStatus = this.query.status;
      } else {
        workOrderStatus = this.query.status;
      }

      let {createTime} = this.query;
      let params = {
        ...this.query,
        ...this.pagination,
        pageNum: this.pagination.currentPage,
        workOrderStatus,
        workOrderEvalStatus,
        creatStartDate: createTime && createTime.length > 0 ? createTime[0] : null,
        creatEndDate: createTime && createTime.length > 0 ? createTime[1] : null,
        orderBy: [
          this.createTimeSortInfo,
        ],
      };
      getQueryWorkOrderPage(params).then(Info => {
        this.orderList = Info.body?.list || [];
        this.pagination.total = Info.body?.total || 0;
      });
    },
    handleActionClick (item) {
      this.$router.push({
        name: 'SASS_REPORT_ORDER_DETAIL',
        query: {
          id: item.id,
          tenantId: item.tenantId,
        },
      });
    },
  },
  computed: {
    ...mapGetters(['actionPermissionMap']),
  },
  filters: {
    dateFormatFilter (val) {
      return dayjs(val).format('YYYY-MM-DD');
    },
    timeFormatFilter (val) {
      return dayjs(val).format('HH:mm');
    },
    emptyReplace (val) {
      return val || '--';
    },
  },
};
</script>
