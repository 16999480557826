import server from '@/request';

export default {

  // 推广渠道列表
  getChannelList (params) {
    return server.post('/xiaozi-saas/wireless/channel/queryPage', params);
  },
  // 推广渠道新增
  addChannel (params) {
    return server.post('/xiaozi-saas/wireless/channel/add', params);
  },
  // 推广渠道详情
  detailChannel (params) {
    return server.post('/xiaozi-saas/wireless/channel/queryDetail', params);
  },
  // 推广渠道编辑
  updateChannel (params) {
    return server.post('/xiaozi-saas/wireless/channel/update', params);
  },
  // 推广渠道编辑状态
  updateStatusChannel (params) {
    return server.post('/xiaozi-saas/wireless/channel/updateStatus', params);
  },
  // 推荐人新增
  addChannelPerson (params) {
    return server.post('/xiaozi-saas/wireless/channelPerson/add', params);
  },
  // 推荐人详情
  detailChannelPerson (params) {
    return server.post('/xiaozi-saas/wireless/channelPerson/queryDetail', params);
  },
  // 推荐人列表
  getChannelPersonLists (params) {
    return server.post('/xiaozi-saas/wireless/channelPerson/queryPage', params);
  },
  // 推荐人编辑
  updateChannelPerson (params) {
    return server.post('/xiaozi-saas/wireless/channelPerson/update', params);
  },
  // 推荐人编辑是否展示
  updateShowFlagChannelPerson (params) {
    return server.post('/xiaozi-saas/wireless/channelPerson/updateShowFlag', params);
  },
  // 推荐人编辑状态
  updateStatusChannelPerson (params) {
    return server.post('/xiaozi-saas/wireless/channelPerson/updateStatus', params);
  },
};
