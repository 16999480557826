<template>
  <div>
    <query-form labelWidth="125px" class="content_block" ref="queryForm" @search="handleSearch" @reset="handleReset">
      <el-form-item label="订单编号：" >
        <el-input placeholder="请输入订单编号" v-model.trim="query.code"></el-input>
      </el-form-item>
      <el-form-item label="商品名称：" >
        <el-input placeholder="请输入商品名称" v-model.trim="query.productName"></el-input>
      </el-form-item>
      <el-form-item label="渠道名称：" >
        <el-input placeholder="请输入渠道名称" v-model.trim="query.channelName"></el-input>
      </el-form-item>
      <el-form-item label="买家名称：" >
        <el-input placeholder="请输入买家名称" v-model.trim="query.buyerName"></el-input>
      </el-form-item>
      <el-form-item label="支付时间：" class="JZCategoryFormType">
        <el-date-picker
          @change='handleSearch'
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
          v-model.trim="query.payTime"
          :default-time="['00:00:00', '23:59:59']">
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="到期时间：" class="JZCategoryFormType">
        <el-date-picker
          @change='handleSearch'
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
          v-model.trim="query.expiryTime"
          :default-time="['00:00:00', '23:59:59']">
        ></el-date-picker>
      </el-form-item>
    </query-form>
    <table-with-pagination :tableData="orderList"
      class="content_block"
      :showRightToolbar="false"
      :columnList="columnList"
      :pagination.sync="pagination"
      @size-change="getTableList"
      @current-change="getTableList"
      :filterConfig="{ remote: true }"
      @filter-change="handleFilterChange"
    />
    <Trade ref="JZTrade" @handleReset='handleReset'></Trade>
  </div>
</template>
<script>
import { getOrderList } from '@/api/order';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import Trade from './components/trade.vue';
export default {
  name: 'ORDER_INDEX',
  mixins: [
    TablePaginationMixin,
  ],
  components: {
    Trade,
  },
  data () {
    return {
      query: {
        code: '',
        productName: '',
        channelName: '',
        payMethod: null,
        payStatus: null,
        invoiceStatus: null,
        buyerName: '',
        startPayTime: '',
        endPayTime: '',
        startExpiryTime: '',
        endExpiryTime: '',
        expiryTime: [],
        payTime: [],
      },
      orderList: [],
      columnList: [
        {
          tooltip: true,
          label: '订单编号',
          minWidth: '180px',
          prop: 'code',
        },
        {
          tooltip: true,
          label: '商品名称',
          minWidth: '200px',
          prop: 'productName',
        },
        {
          tooltip: true,
          label: '服务用户',
          minWidth: '200px',
          prop: 'objName',
        },
        {
          tooltip: true,
          label: '渠道',
          minWidth: '100px',
          prop: 'channelName',
        },
        {
          tooltip: true,
          label: '订单金额',
          minWidth: '80px',
          prop: 'totalAmount',
        },
        {
          tooltip: true,
          label: '买家',
          minWidth: '150px',
          prop: 'buyerName',
        },
        {
          tooltip: true,
          label: '支付时间',
          minWidth: '150px',
          prop: 'payTime',
          format: val=>this.$options.filters['date-format'](val),
        },
        {
          tooltip: true,
          label: '到期时间',
          minWidth: '150px',
          prop: 'expiryTime',
          format: val=>this.$options.filters['date-format'](val),
        },
        {
          tooltip: true,
          label: '支付方式',
          minWidth: '100px',
          prop: 'payMethod',
          filters: [
            {label: '微信支付', value: '2'},
            {label: '支付宝', value: '1'},
            {label: '银行汇款', value: '3'},
          ],
          format: (val) => {
            return val ? ['支付宝', '微信支付', '银行汇款'][val * 1 - 1] : '--';
          },
          // filterMethod: this.filterPaymentStatusMethod,
          filterMultiple: false,
        },
        {
          tooltip: true,
          label: '支付状态',
          minWidth: '100px',
          prop: 'payStatus',
          filters: [
            {label: '未支付', value: '1'},
            {label: '已支付', value: '2'},
            {label: '支付失败', value: '3'},
            {label: '审核中', value: '4'},
            {label: '审核不通过', value: '5'},
          ],
          format: (val) => {
            return val ? ['未支付', '已支付', '支付失败', '审核中', '审核不通过'][val * 1 - 1] : '--';
          },
          // filterMethod: this.filterPaymentStatusMethod,
          filterMultiple: false,
        },
        {
          tooltip: true,
          label: '开票状态',
          minWidth: '100px',
          prop: 'invoiceStatus',
          format: (val) => {
            return val ? ['未申请', '已申请', '开票中', '开票完成', '已寄出'][val] : '--';
          },
          filters: [{label: '未申请', value: '0'}, {label: '已申请', value: '1'}, {label: '开票中', value: '2'}, {label: '开票完成', value: '3'}, {label: '已寄出', value: '4'}],
          // filterMethod: this.filterStatusMethod,
          filterMultiple: false,
        },
        {
          tooltip: true,
          label: '操作',
          minWidth: '100px',
          fixed: 'right',
          prop: 'id',
          render: (h, {row}) => {
            let actions = [];
            if (row.payStatus === '4' && row.payMethod === '3') {
              actions.push(<span v-auth={'saas_orderManage:reviewReceipt'} class='cursor-pointer text-primary' onClick={this.handleActionClick.bind(this, row, '1')}>审核回单</span>);
            }
            if (row.payStatus === '2' && row.payMethod === '3') {
              actions.push(<span v-auth={'saas_orderManage:queryReceipt'} class='cursor-pointer text-primary' onClick={this.handleActionClick.bind(this, row, '2')}>查看回单</span>);
            }
            return <div>{...actions}</div>;
          },
        },
      ],
    };
  },
  methods: {
    // 回单相关操作 审核回单 查看回单
    handleActionClick (info, status) {
      this.$refs.JZTrade.currentStatus = status;
      this.$refs.JZTrade.id = info.id;
      this.$refs.JZTrade.initPage();
    },
    handleFilterChange (params) {
      this.pagination.currentPage = 1;
      this.query[params.property] = params.values.length ? params.values.join(',') : null;
      this.handleSearch();
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    handleReset () {
      this.query = {
        code: '',
        productName: '',
        channelName: '',
        payMethod: null,
        payStatus: null,
        invoiceStatus: null,
        buyerName: '',
        startPayTime: '',
        endPayTime: '',
        startExpiryTime: '',
        endExpiryTime: '',
        expiryTime: [],
        payTime: [],
      };
      this.handleSearch();
    },
    getTableList () {
      let param = {
        ...this.query,
        pageSize: this.pagination.pageSize,
        pageNum: this.pagination.currentPage,
        startPayTime: this.query.payTime && this.query.payTime.length > 0 ? this.query.payTime[0] : '',
        endPayTime: this.query.payTime && this.query.payTime.length > 0 ? this.query.payTime[1] : '',
        startExpiryTime: this.query.expiryTime && this.query.expiryTime.length > 0 ? this.query.expiryTime[0] : '',
        endExpiryTime: this.query.expiryTime && this.query.expiryTime.length > 0 ? this.query.expiryTime[1] : '',
      };
      getOrderList(param).then(res => {
        this.orderList = res.body.list;
        this.pagination.total = res.body.total;
      });
    },
  },
};
</script>
<style scoped lang="scss">
/deep/ .JZCategoryFormType .el-date-editor.el-input{
  width:100%;
}
</style>

