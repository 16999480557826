import Enum from '@/utils/Enum';

const auditStatusList = [
  {
    value: null,
    name: '全部',
  },
  {
    value: 'WAIT',
    name: '待审核',
  },
  {
    value: 'PASS',
    name: '审核成功',
  },
  {
    value: 'REJECT',
    name: '审核失败',
  },
];
export default new Enum(auditStatusList);
export { auditStatusList as AuditStatusList };
