<script>
import render from '../render/render.js';
import TableLayout from '../render/tableRender';
import ChildFormRender from '../render/childFormRender';
import { getDefaultValueByTag, getDisplayValue, buildRules, flatternFields } from '../utils/component.js';
import _ from 'lodash';

const layouts = {
  colFormItem (h, scheme) {
    const { formConfCopy } = this;
    const config = scheme.__config__;
    const listeners = buildListeners.call(this, scheme);

    let labelWidth = config.labelWidth ? `${config.labelWidth}px` : null;
    if (config.showLabel === false) labelWidth = '0';
    let item = null;
    if (formConfCopy.disabled) {
      try {
        item = <span>{getDisplayValue(scheme, this.formModel)}</span>;
      } catch (error) {
        item = <render conf={scheme} on={listeners} formModel={this.formModel}/>;
      }
    } else {
      item = <render conf={scheme} on={listeners} formModel={this.formModel}/>;
    }
    return (
      <el-col span={config.span}>
        <el-form-item label-width={labelWidth} prop={config.renderKey.toString()}
          label={config.showLabel ? config.label + '：' : ''} >
          {item}
        </el-form-item>
      </el-col>
    );
  },
  rowFormItem (h, scheme) {
    let child = renderChildren.apply(this, arguments);
    if (scheme.type === 'flex') {
      child = <el-row type={scheme.type} justify={scheme.justify} align={scheme.align}>
        {child}
      </el-row>;
    }
    return (
      <el-col span={scheme.span}>
        <el-row gutter={scheme.gutter}>
          {child}
        </el-row>
      </el-col>
    );
  },

  groupRowFormItem (h, scheme) {
    let child = renderChildren.apply(this, arguments);
    const config = scheme.__config__;
    if (scheme.type === 'flex') {
      child = <el-row type={scheme.type} justify={scheme.justify} align={scheme.align}>
        {child}
      </el-row>;
    }
    return (
      <el-col span={scheme.span} >
        <content-block title={config.label}>
          <el-row gutter={scheme.gutter}>
            {child}
          </el-row>
        </content-block>
      </el-col>
    );
  },

  tableColumnFormItem (h, scheme) {
    const config = scheme.__config__;

    let labelWidth = config.labelWidth ? `${config.labelWidth}px` : null;
    if (config.showLabel === false) labelWidth = '0';
    return (
      <el-col span={config.span}>
        <el-form-item label-width={labelWidth} prop={config.renderKey.toString()}
          label={config.showLabel ? config.label : ''} >
          <TableLayout scheme={scheme} formModel={this.formModel}/>
        </el-form-item>
      </el-col>
    );
  },
  childFormItem (h, scheme) {
    const config = scheme.__config__;
    let labelWidth = config.labelWidth ? `${config.labelWidth}px` : null;
    if (config.showLabel === false) labelWidth = '0';
    return (
      <el-col span={config.span}>
        <el-form-item label-width={labelWidth} prop={config.renderKey.toString()}
          label={config.showLabel ? config.label : ''} >
          <ChildFormRender scheme={scheme} formModel={this.formModel} tenantId={this.tenantId}/>
        </el-form-item>
      </el-col>
    );
  },
};

function renderFrom (h, slotdiv) {
  const { formConfCopy } = this;
  return formConfCopy ? (
    <el-row gutter={formConfCopy.gutter}>
      <el-form
        label-position={formConfCopy.labelPosition}
        disabled={formConfCopy.disabled}
        label-width={`${formConfCopy.labelWidth}px`}
        ref={formConfCopy.formRef}
        // model不能直接赋值 https://github.com/vuejs/jsx/issues/49#issuecomment-472013664
        props={{ model: this.formModel }}
        rules={this.formRules}
        class='w-100'
      >
        {formConfCopy.fields?.length && renderFormItem.call(this, h, formConfCopy.fields)}
        { slotdiv && viewformBtns.call(this, h, slotdiv)}
      </el-form>
    </el-row>
  ) : '';
}

const viewformBtns = (h, slotVNode) => {
  return <el-col>
    <el-form-item size="large">
      {slotVNode()}
    </el-form-item>
  </el-col>;
};

// {formConfCopy.formBtns && formBtns.call(this, h)}
// function formBtns (h) {
//   console.log(h);
//   return <el-col>
//     <el-form-item size="large">
//       <el-button type="primary" onClick={this.submitForm}>提交</el-button>
//       <el-button onClick={this.resetForm}>重置</el-button>
//     </el-form-item>
//   </el-col>;
// }

function renderFormItem (h, elementList) {
  let filter = t=>{
    if (t.__config__?.layout !== 'groupRowFormItem') {
      return t.__config__.visible || t.__config__.visible === undefined || t.__config__.visible === null;
    }
    // 排除分组下面children都隐藏的分组渲染
    return t.__config__.children.some(c=>c.__config__.visible || c.__config__.visible === undefined || c.__config__.visible === null);
  };
  return elementList?.length ? elementList.filter(filter).map(scheme => {
    const config = scheme.__config__;
    const layout = layouts[config.layout];

    if (layout) {
      return layout.call(this, h, scheme, elementList);
    }
    throw new Error(`没有与${config.layout}匹配的layout`);
  }) : [];
}

function renderChildren (h, scheme) {
  const config = scheme.__config__;
  if (!Array.isArray(config.children)) return null;
  return renderFormItem.call(this, h, config.children.filter(t=>t.__config__.visible !== false));
}

function setValue (event, config, scheme) {
  this.$set(this.formModel, scheme.__config__.renderKey, event);
}

function buildListeners (scheme) {
  const config = scheme.__config__;
  const methods = this.formConf.__methods__ || {};
  const listeners = {};

  // 给__methods__中的方法绑定this和event
  Object.keys(methods).forEach(key => {
    listeners[key] = event => methods[key].call(this, event);
  });
  // 响应 render.js 中的 vModel $emit('input', val)
  listeners.input = event => setValue.call(this, event, config, scheme);

  return listeners;
}

export default {
  components: {
    render,
    TableLayout,
  },
  props: {
    formConf: {
      type: Object,
      required: true,
    },
    formModel: {
      type: Object,
      required: true,
    },
    tenantId: {
      type: String,
    },
  },
  data () {
    const data = {
      formConfCopy: _.cloneDeep(this.formConf),
      formRules: {},
      fieldMap: {},
    };
    return data;
  },
  watch: {
    formConf: {
      immediate: true,
      handler (val) {
        this.formConfCopy = _.cloneDeep(val);
        this.flatternFields();
        this.processBindFilterParamAndValue();
        buildRules(this.formConfCopy.fields, this.formRules, this.fieldMap);
      },
    },
  },
  methods: {
    resetForm () {
      this.formConfCopy = _.cloneDeep(this.formConf);
      this.$refs[this.formConf.formRef].resetFields();
    },
    validForm () {
      return this.$refs[this.formConf.formRef].validate();
    },
    flatternFields () {
      let fieldMap = {};
      flatternFields(this.formConfCopy.fields, fieldMap);
      this.fieldMap = fieldMap;
    },
    processBindFilterParamAndValue () {
      this.fieldMap && Object.keys(this.fieldMap).forEach(key=>{
        let fieldItem = this.fieldMap[key];
        if (fieldItem?.__config__?.from && fieldItem?.__config__?.from?.key) {
          this.$watch(`formModel.${fieldItem?.__config__?.from?.key}`, (val)=>{
            this.$set(this.formModel, fieldItem?.__config__?.renderKey, val[fieldItem?.__config__?.from?.prop]);
          });
        }
        // 过滤筛选条件绑定对象变更时，重置当前控件绑定的值
        if (fieldItem?.__config__?.filterParam) {
          Object.keys(fieldItem?.__config__?.filterParam).forEach(f=>{
            let source = fieldItem?.__config__?.filterParam[f]?.source;
            if (source) {
              this.$watch(`formModel.${source.key}.${source.prop}`, ()=>{
                this.$set(this.formModel, fieldItem?.__config__?.renderKey, getDefaultValueByTag(fieldItem));
              });
            }
          });
        }
      });
    },
  },
  render (h) {
    return renderFrom.call(this, h, this.$scopedSlots.footer);
  },
  created () {
    this.flatternFields();
    this.processBindFilterParamAndValue();
    buildRules(this.formConfCopy.fields, this.formRules, this.fieldMap);
  },
};
</script>
