<template>
  <div ref="boardBox" class="c-manual-sign">
    <template v-if="inputDisabled">
      <img v-if="imgUrl" :src="imgUrl" class="signImg">
      <span v-else>暂无签名信息</span>
    </template>
    <template v-else>
      <img v-if="!isEdting" :src="imgUrl" class="signImg">
      <canvas
        v-else
        ref="board"
        @mousedown="pcStart"
        @mousemove="pcMove"
        @mouseup="pcEnd"
      />
      <div class="sign-action" >
        <el-button  size="mini" v-show="!isEdting" plain type="primary" @click="handleEdit" >编辑</el-button>
        <el-button  size="mini" v-show="isEdting"  plain type="primary" @click="clearCanvas">清空</el-button>
        <el-button  size="mini"  v-show="isEdting"   @click="isEdting=!isEdting">取消</el-button>
        <el-button  size="mini"  v-show="isEdting && hasContent" type="primary"  @click="handleSave">确定</el-button>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import mixin from './mixin';
export default {
  name: 'C_MANUAL_SIGN',
  mixins: [mixin],
  props: {
    value: String,
  },
  data () {
    return {
      ctx: null,
      point: {
        x: 0,
        y: 0,
      },
      imgUrl: this.value,
      isEdting: false,
      hasContent: false,
    };
  },
  watch: {
    value () {
      if (this.value !== this.imgUrl) {
        this.imgUrl = this.value;
      }
    },
    isEdting () {
      if (this.isEdting) {
        this.$nextTick(()=>{
          const { board } = this.$refs; // 获取DOM
          board.width = this.$refs.boardBox.offsetWidth; // 设置画布宽
          board.height = this.$refs.boardBox.offsetHeight; // 设置画布高
          this.ctx = this.$refs.board.getContext('2d'); // 二维绘图
          this.ctx.lineWidth = 2;
          this.ctx.lineCap = 'round';
          this.ctx.lineJoin = 'round';
          this.ctx.clearRect(0, 0, this.$refs.board.width, this.$refs.board.width);
          this.ctx.fillStyle = '#ffffff';
          this.ctx.fillRect(0, 0, this.$refs.board.width, this.$refs.board.width);
        });
      }
    },
  },
  methods: {
    handleEdit () {
      this.isEdting = true;
      if (this.imgUrl) {
        var img = new Image();
        img.crossOrigin = 'anonymous';
        img.src = this.imgUrl;
        img.onload = ()=> {
          var ptrn = this.ctx.createPattern(img, 'no-repeat');
          this.ctx.fillStyle = ptrn;
          this.ctx.fillRect(0, 0, this.$refs.board.width, this.$refs.board.height);
        };
      } else {
        this.hasContent = false;
      }
    },
    handleSave () {
      if (!this.hasContent) {
        this.isEdting = false;
        return;
      }
      this.$refs.board.toBlob(blob => {
        const filename = 'sin' + Date.now() + Math.random() + '.jpg';
        let newForm = new FormData();
        newForm.set('file', blob);
        newForm.set('key', filename);
        newForm.set('token', this.qiniuToken);
        axios.post('https://upload.qiniup.com', newForm).then(r=>{
          this.imgUrl = `${this.downloadDomain}/${r.data.key}`;
          this.$emit('input', this.imgUrl);
          this.isEdting = false;
        });
      }, 'image/png');
    },
    clearCanvas () {
      this.hasContent = false;
      this.ctx.clearRect(0, 0, this.$refs.board.width, this.$refs.board.height);
    },
    // 鼠标按下(开始)
    pcStart (e) {
      const x = e.offsetX;
      const y = e.offsetY; // 获取鼠标在画板（canvas）的坐标
      this.point.x = x;
      this.point.y = y;
      this.ctx.beginPath();
      this.moving = true;
    },
    // 鼠标移动（移动中...）
    pcMove (e) {
      if (this.moving) {
        this.hasContent = true;
        const x = e.offsetX;
        const y = e.offsetY; // 获取鼠标在画板（canvas）的坐标
        this.ctx.moveTo(this.point.x, this.point.y); // 把路径移动到画布中的指定点，不创建线条(起始点)
        this.ctx.lineTo(x, y); // 添加一个新点，然后创建从该点到画布中最后指定点的线条，不创建线条
        this.ctx.stroke(); // 绘制
        this.point.x = x;
        this.point.y = y; // 重置点坐标为上一个坐标
      }
    },
    // 鼠标松开（结束）
    pcEnd () {
      if (this.moving) {
        this.ctx.closePath(); // 停止绘制
        this.moving = false; // 关闭绘制开关
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.c-manual-sign {
  // width: 600px;
  height: 300px;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #DCDFE6;
  border-radius: 4;

  &>img,&>canvas {
    display: block;
    height: 100%;
    width: 100%;
  }

  &>.sign-action {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}
</style>
