import Enum from '@/utils/Enum';

const TenantStatusEnumList = [
  {
    value: 0,
    name: 'wait',
    desc: '待试用审核',
  },
  {
    value: 1,
    name: 'run',
    desc: '试用中',
  },
  {
    value: 2,
    name: 'expire',
    desc: '已到期',
  },
  {
    value: 3,
    name: 'reject',
    desc: '已驳回',
  },
  {
    value: 4,
    name: 'close',
    desc: '已关闭',
  },
  {
    value: 5,
    name: 'waitActive',
    desc: '待激活审核',
  },
  {
    value: 6,
    name: 'use',
    desc: '使用中',
  },
];

export default new Enum(TenantStatusEnumList);
export { TenantStatusEnumList };
