<template>
  <div>
    <query-form labelWidth="120px" class="content_block" label-position='right' ref="queryForm" >
      <el-form-item label="推广渠道编号：" >
        <el-input placeholder="请输入推广渠道编号" v-model.trim="query.code"></el-input>
      </el-form-item>
      <el-form-item label="推广渠道名称：" >
        <el-input placeholder="请输入推广渠道名称" v-model.trim="query.name"></el-input>
      </el-form-item>
      <el-form-item label="状态：" >
        <el-select v-model="query.status" @change="handleSearch" placeholder='请选择状态'>
          <el-option v-for="i in statusList" :key="i.value" :label="i.label" :value="i.value"></el-option>
        </el-select>
      </el-form-item>
      <el-button slot="action" type='primary' @click="handleSearch">搜索</el-button>
    </query-form>
    <table-with-pagination
      :tableData="targetLists"
      class="content_block"
      ref="JZChannelTable"
      :columnList="columnList"
      :pagination.sync="pagination"
      :sortInfo.sync="updateSortInfo"
      @size-change="getTargetLists"
      @current-change="getTargetLists"
      @sort-change="getTargetLists"
      :checkbox-config="{reserve:true}"
      @selection-change="handleSelectionChange"
      :leftToolbar="tableButtons"
      ></table-with-pagination>
      <el-dialog
          :title="currentTitle"
          :visible.sync="isShow"
          width="400px"
          :append-to-body='true'
          :destroyOnClose='true'
          :close-on-click-modal='false'
        >
        <div class="content" style="max-height: 400px;overflow: auto;">
          <el-form
            :model="JZChannelForm"
            ref="JZChannelForm"
            :rules="JZChannelFormRules"
            :inline="true"
            class="content_block"
            label-width="125px"
            v-if="step===1"
          >
            <el-row >
              <el-col :span="24">
                <el-form-item
                    prop="name"
                    label="推广渠道名称："
                  >
                  <el-input v-model.trim="JZChannelForm.name" placeholder="请输入推广渠道名称" maxlength='20' show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <p v-else-if="step===2" class="warningInfo">推广渠道已创建，是否需要新增推荐人？</p>
          <el-form
            :model="JZRecommenderForm"
            ref="JZRecommenderForm"
            :rules="JZRecommenderFormRules"
            :inline="true"
            class="content_block"
            label-width="125px"
            v-if="step===3"
          >
            <el-row >
              <el-col :span="24">
                <el-form-item
                    prop=""
                    label="渠道名称："
                  >
                  {{JZChannelForm.name}}
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                    prop="name"
                    label="推荐人名称："
                  >
                  <el-input v-model.trim="JZRecommenderForm.name" placeholder="请输入推荐人名称" maxlength='20' show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer " style="text-align:right;" >
            <el-button @click="handleCancel" size="mini">取 消</el-button>
            <el-button type="primary" @click="handleEnsure" size="mini">确定</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
import Promotion from '@/api/promotion';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import { mapGetters } from 'vuex';
export default {
  name: 'Promotion',
  mixins: [
    TablePaginationMixin,
  ],
  methods: {
    handleEnsure () {
      if (this.step === 1) {
        this.$refs.JZChannelForm.validate((valid) => {
          if (valid) {
            Promotion.addChannel({name: this.JZChannelForm.name}).then(res => {
              this.step = 2;
              this.handleSearch();
              this.JZRecommenderForm = {
                name: '',
                channelId: res.body,
              };
            });
          }
        });
      } else if (this.step === 2) {
        this.step = 3;
      } else if (this.step === 3) {
        this.$refs.JZRecommenderForm.validate((valid) => {
          if (valid) {
            Promotion.addChannelPerson(this.JZRecommenderForm).then(() => {
              let currentId = this.JZRecommenderForm.channelId;
              this.handleCancel();
              this.$router.push({
                name: 'PromotionDetail',
                query: {
                  id: currentId,
                },
              });
            });
          }
        });
      }
    },
    handleCancel () {
      this.isShow = false;
      this.step = 1;
      this.JZChannelForm = {
        id: '',
        name: '',
      };
      this.JZRecommenderForm = {
        name: '',
        channelId: '',
      };
    },
    handleActionClick ({id}, action) {
      if (action === 'detail') {
        this.$router.push({
          name: 'PromotionDetail',
          query: {
            id,
          },
        });
      } else if (action === 'process') {
        this.$router.push({
          name: 'PromotionDetail',
          query: {
            id,
          },
        });
      }
    },
    handleUpdateStatusChannel (info, status) {
      let title = status === '1' ? '启用' : '禁用';
      let content = status === '1' ? '' : '<br/>禁用后,当前推广链将不计入推广费，请慎用！！！';
      let message = `确认${title}【${info.name}】渠道吗？${content}`;
      this.$confirm(message, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true, // 允许确认框内容为html格式
        callback: action => {
          if (action === 'confirm') {
            Promotion.updateStatusChannel({idList: [info.id], status: status}).then(() => {
              this.handleSearch();
            });
          }
        },
      });
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTargetLists();
    },
    getTargetLists () {
      Promotion.getChannelList({...this.query, pageSize: this.pagination.pageSize, pageNum: this.pagination.currentPage }).then(res => {
        this.targetLists = res.body.list.map(item => {
          return {
            ...item,
            isStatus: false,
          };
        });
        this.pagination.total = res.body.total;
      });
    },
    handleSelectionChange (currentLists) {
      this.selectedLists = currentLists;
    },
    handleBatchUpdateStatusChannel (status) {
      let title = status === '1' ? '批量启用' : '批量禁用';
      let idList = this.selectedLists.map(item=>item.id);
      let content = status === '1' ? '' : '<br/>禁用后,选中的推广链将不计入推广费，请慎用！！！';
      let message = `确认${title}选中的【${idList.length}】个渠道吗？${content}`;
      this.$confirm(message, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true, // 允许确认框内容为html格式
        callback: action => {
          if (action === 'confirm') {
            Promotion.updateStatusChannel({idList, status}).then(() => {
              this.selectedLists = [];
              this.$refs.JZChannelTable.setCheckboxRow([], false);
              this.$refs.JZChannelTable.clearCheckboxRow();
              this.handleSearch();
            });
          }
        },
      });
    },
    handleDown (imgsrc, name) {
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous');
      image.onload = function () {
        var canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL('image/png'); // 得到图片的base64编码数据
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = name || '二维码'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },
    handleEdit (row, index) {
      this.targetLists.splice(index, 1, {
        ...row,
        newName: row.name,
        isStatus: true,
      });
    },
    handleSave (row) {
      Promotion.updateChannel({id: row.id, name: row.newName}).then(() => {
        this.handleSearch();
      });
    },
    handleCancelForChannel (row, index) {
      this.targetLists.splice(index, 1, {
        ...row,
        isStatus: false,
      });
    },
  },
  created () {
    this.handleSearch();
  },
  computed: {
    ...mapGetters(['actionPermissionMap']),
    currentIdLists () {
      return this.targetLists.map(item => item.id);
    },
    currentTitle () {
      let currentStep = this.step;
      return currentStep === 1 ? '新增推广渠道' : currentStep === 2 ? '提示信息' : currentStep === 3 ? '新增推荐人' : '';
    },
    tableButtons () {
      let currentLists = [
        {
          name: '新增推广渠道',
          status: 'primary',
          size: 'small',
          code: 'addNewChannel',
          icon: 'vxe-icon--plus',
          permission: 'saas_promotionList:add',
          click: ()=> {
            this.step = 1;
            this.JZChannelForm = {
              id: '',
              name: '',
            };
            this.isShow = true;
          },
        },
        {
          name: '批量启用',
          status: 'primary',
          size: 'small',
          code: 'batchEnable',
          icon: '',
          disabled: !this.selectedLists.length,
          permission: 'saas_promotionList:batchEnable',
          click: () => { this.handleBatchUpdateStatusChannel('1'); },
        },
        {
          name: '批量禁用',
          status: 'primary',
          size: 'small',
          code: 'batchDisable',
          icon: '',
          permission: 'saas_promotionList:batchDisable',
          disabled: !this.selectedLists.length,
          click: () => { this.handleBatchUpdateStatusChannel('2'); },
        },
      ];
      return currentLists;
    },
  },
  data () {
    return {
      JZChannelForm: {
        id: '',
        name: '',
      },
      JZChannelFormRules: {
        name: [
          {
            required: true,
            message: '请输入推广渠道名称',
            trigger: ['blur', 'change'],
          },
        ],
      },
      JZRecommenderForm: {
        name: '',
        channelId: '',
      },
      JZRecommenderFormRules: {
        name: [
          {
            required: true,
            message: '请输入推荐人名称',
            trigger: ['blur', 'change'],
          },
        ],
      },
      isShow: false,
      step: 1,
      selectedLists: [],
      statusList: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '启用',
          value: '1',
        },
        {
          label: '禁用',
          value: '2',
        },
      ],
      query: {
        code: '',
        name: '',
        status: '',
      },
      targetLists: [],
      columnList: [
        {
          tooltip: true,
          label: '全选',
          minWidth: '150px',
          type: 'checkbox',
          fixed: 'left',
        },
        {
          tooltip: true,
          label: '推广渠道编码',
          minWidth: '160px',
          prop: 'code',
          fixed: 'left',
          render: (h, {row}) => {
            if (this.actionPermissionMap['saas_promotionList:detail']) {
              return <span class='cursor-pointer text-primary' onClick={this.handleActionClick.bind(this, row, 'detail')}>{row.code}</span>;
            } else {
              return <span >{row.code}</span>;
            }
          },
        },
        {
          tooltip: true,
          label: '渠道名称',
          minWidth: '150px',
          prop: 'name',
          render: (h, {row}) => {
            if (row.isStatus) {
              return h('el-input', {
                props: {
                  size: 'small',
                  value: row.newName,
                  showWordLimit: true,
                },
                attrs: {
                  maxlength: 20,
                },
                on: {
                  input: val => { row.newName = val; },
                },
              });
            } else {
              return h('span', row.name);
            }
          },
        },
        {
          tooltip: true,
          label: '推广链接',
          minWidth: '150px',
          prop: 'url',
        },
        {
          tooltip: true,
          label: '二维码',
          minWidth: '150px',
          prop: 'qrCode',
          render: (h, {row}) => {
            return <div><el-image preview-src-list={[row.qrCode]} style={'width:40px;height:40px;vertical-align: middle;margin-right:10px'} src={row.qrCode} ></el-image><el-link v-auth={'saas_promotionList:download'} type={'primary'} icon={'el-icon-download'} onClick={this.handleDown.bind(this, row.qrCode, `${row.name}二维码`)}>下载</el-link></div>;
          },
        },
        {
          tooltip: true,
          label: '创建人',
          minWidth: '150px',
          prop: 'createUserName',
        },
        {
          tooltip: true,
          label: '创建时间',
          minWidth: '150px',
          prop: 'createTime',
          format: this.$options.filters['date-format'],
        },
        {
          tooltip: true,
          label: '状态',
          minWidth: '150px',
          prop: 'statusDesc',
        },
        {
          label: '操作',
          minWidth: '180px',
          fixed: 'right',
          render: (h, {row, index}) => {
            let actions = [];
            if (row.status === '1') {
              actions.push(<span class='cursor-pointer text-primary' v-auth={'saas_promotionList:disable'} onClick={this.handleUpdateStatusChannel.bind(this, row, '2')}>禁用</span>);
              if (row.isStatus) {
                actions.push(<span class='cursor-pointer text-primary margin-left' v-auth={'saas_promotionList:edit'} onClick={this.handleSave.bind(this, row)}>保存</span>);
                actions.push(<span class='cursor-pointer text-primary margin-left' v-auth={'saas_promotionList:edit'} onClick={this.handleCancelForChannel.bind(this, row, index)}>取消</span>);
              } else {
                actions.push(<span class='cursor-pointer text-primary margin-left' v-auth={'saas_promotionList:edit'} onClick={this.handleEdit.bind(this, row, index)}>编辑</span>);
              }
            } else if (row.status === '2') {
              actions.push(<span class='cursor-pointer text-primary' v-auth={'saas_promotionList:enable'} onClick={this.handleUpdateStatusChannel.bind(this, row, '1')}>启用</span>);
            }
            return <div>{...actions}</div>;
          },
        },
      ],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
/deep/ .el-dialog .el-dialog__header{
    border-bottom: 1px solid #efefef;
}
/deep/ .el-dialog .el-dialog__footer{
    border-top: 1px solid #efefef;
}
/deep/ .warningInfo{
  display: inline-block;
  margin: 20px auto;
  font-size: 16px;
  padding: 20px;
  font-family: 'Arial Normal', 'Arial';
  font-weight: 400;
  font-style: normal;
}
</style>
