import dayjs from 'dayjs';
import _ from 'lodash';
export function getDeepValue (key, data) {
  return key.split('.').reduce((p, c)=>{
    return p && p[c];
  }, data);
}
export function getDisplayValue (field, model) {
  let config = field.__config__ || field;
  let value = getDeepValue(config.renderKey, model);
  let getOptionValue = (isMultiple)=>{
    if (field.__slot__?.options) {
      return isMultiple
        ? field.__slot__.options.filter(t => value && value.indexOf(t.value) >= 0)
        : field.__slot__.options.find(t => t.value === value);
    } else {
      return null;
    }
  };
  let timestampFormat = (val, format = 'yyyy-MM-dd')=>{
    if (val === null || val === undefined) {
      return '';
    }
    return dayjs(+val).format(format.replace(/y/g, 'Y').replace(/d/g, 'D'));
  };
  if (config.tag === 'el-select') {
    if (field.multiple) {
      return value ? value.map(t=>t.label).join(',') : '';
    } else {
      return value?.label;
    }
  } else if (config.tag === 'span' || config.tag === 'el-input') {
    return value || '';
  } else if (config.tag === 'el-input-number') {
    return _.isNil(value) ? '' : value;
  } else if (config.tag === 'el-radio-group') {
    value = getOptionValue();
    return value?.label;
  } else if (config.tag === 'el-checkbox-group') {
    value = getOptionValue(true);
    return value ? value.map(t=>t.label).join(',') : '';
  } else if (config.tag === 'el-time-picker') {
    if (!value) {
      return '';
    }
    if (field['is-range']) {
      if (field['value-format'] === 'timestamp') {
        return value.map(t=>timestampFormat(t, field.format)).join(field['range-separator'] || '~');
      } else {
        return value.join(field['range-separator'] || '~');
      }
    } else {
      if (field['value-format'] === 'timestamp') {
        return timestampFormat(value, field.format);
      } else {
        return value;
      }
    }
  } else if (config.tag === 'el-date-picker') {
    if (!value) {
      return '';
    }
    if (field['type'] === 'daterange' || field['type'] === 'datetimerange') {
      if (field['value-format'] === 'timestamp') {
        return value.map(t=>timestampFormat(t, field.format)).join(field['range-separator'] || '~');
      } else {
        return value.join(field['range-separator'] || '~');
      }
    } else {
      if (field['value-format'] === 'timestamp') {
        // element的format是yyyy-MM-dd 需要转换成dayjs YYYY-MM-DD
        return timestampFormat(value, field.format);
      } else {
        return value;
      }
    }
  } else if (config.tag === 'c-province-address') {
    return `${value?.provinceName || ''}${value?.cityName || ''}${value?.areaName || ''}${value?.detailAddress || ''}`;
  } else if (config.tag === 'c-location') {
    return `${value?.address || ''}`;
  } else if (config.tag === 'c-department-selector') {
    return `${value?.name || ''}`;
  } else if (config.tag === 'c-user-selector') {
    return `${value?.name || ''}`;
  } else if (config.tag === 'c-customer-selector') {
    return `${value?.name || ''}`;
  } else if (config.tag === 'c-contact-selector') {
    return `${value?.name || ''}`;
  } else if (config.tag === 'c-device-selector' || config.tag === 'c-device-common-selector') {
    return `${value?.name || ''}`;
  } else if (config.tag === 'c-archive-collection') {
    return `${value.name || ''}`;
  } else if (config.tag === 'c-contact-address-selector') {
    return `${value?.address || ''}`;
  } else {
    throw new Error(`${config.tag}未设置默认展示值`);
  }
}

export function getDefaultValueByTag (field) {
  let config = field.__config__ || field;
  if (config.defaultValue !== null && config.defaultValue !== undefined) {
    return config.defaultValue;
  }
  if (config && config.tag === 'el-input') {
    return undefined;
  } else if (config.tag === 'span') {
    return undefined;
  } else if (config.tag === 'el-input-number') {
    return undefined;
  } else if (config.tag === 'c-manual-sign') {
    return undefined;
  } else if (config.tag === 'el-select') {
    if (field.multiple) {
      return [];
    } else {
      return {};
    }
  } else if (config.tag === 'c-archive-collection') {
    return {};
  } else if (config.tag === 'el-radio-group') {
    return undefined;
  } else if (config.tag === 'el-checkbox-group') {
    return [];
  } else if (config.tag === 'el-switch') {
    return false;
  } else if (config.tag === 'el-slider') {
    return undefined;
  } else if (config.tag === 'el-time-picker') {
    return undefined;
  } else if (config.tag === 'el-date-picker') {
    return undefined;
  } else if (config.tag === 'el-rate') {
    return undefined;
  } else if (config.tag === 'upload') {
    return [];
  } else if (config.tag === 'c-province-address') {
    return {};
  } else if (config.tag === 'c-location') {
    return {};
  } else if (config.tag === 'c-department-selector') {
    return {};
  } else if (config.tag === 'c-user-selector') {
    return {};
  } else if (config.tag === 'c-contact-time') {
    return {};
  } else if (config.tag === 'c-customer-selector') {
    return {};
  } else if (config.tag === 'c-contact-selector') {
    return {};
  } else if (config.tag === 'c-contact-address-selector') {
    return {};
  } else if (config.tag === 'c-device-selector' || config.tag === 'c-device-common-selector') {
    return {};
  } else if (config.tag === 'c-work-hour') {
    return [];
  } else if (config.tag === 'c-spare-part-list') {
    return [];
  } else if (config.tag === 'c-maintenance-list') {
    return [];
  } else if (config.layout === 'tableColumnFormItem' || config.layout === 'childFormItem') {
    return [];
  } else {
    throw new Error(`${config.tag}未设置默认值类型`);
  }
}

const ruleTrigger = {
  'el-input': 'blur',
  'el-input-number': 'blur',
  'el-select': 'change',
  'el-radio-group': 'change',
  'el-checkbox-group': 'change',
  'el-cascader': 'change',
  'el-time-picker': 'change',
  'el-date-picker': 'change',
  'el-rate': 'change',
};

// 构建表单校验规则
export function buildRules (componentList, rules) {
  componentList?.length && componentList.forEach(cur => {
    const config = cur.__config__;
    if (!config.regList) {
      config.regList = [];
    }
    if (config.children && config.layout === 'groupRowFormItem') {
      buildRules(config.children, rules);
    } else {
      let defaultValue = getDefaultValueByTag(cur);
      if (config.required) {
        const required = { message: `${config.label}不能为空`, required: true, trigger: ['blur', 'change'] };
        if (Array.isArray(defaultValue)) {
          required.type = 'array';
          required.message = `请至少选择一个${config.label}`;
        } else if (_.isObject(defaultValue)) {
          required.type = 'object';
        }
        config.regList.push(required);
        if (_.isObject(defaultValue)) {
          config.regList.push({
            validator: (rule, value, callback) => {
              if (!value || Object.keys(value).length === 0) {
                callback(new Error(`${config.label}不能为空`));
              }
              callback();
            },
            trigger: ['blur', 'change'],
          });
        }
      }
      rules[config.renderKey] = config.regList.map(item => {
        item.pattern && (item.pattern = new RegExp(item.pattern, 'g'));
        item.trigger = ruleTrigger && ruleTrigger[config.tag];
        return item;
      });
    }
  });
}

// 拍平自定义表单所有控件对应的字段
export function flatternFields (fields, fieldMap) {
  fieldMap = fieldMap || {};
  fields && fields.forEach(f=>{
    if (f?.__config__?.children) {
      flatternFields(f?.__config__?.children, fieldMap);
    } else {
      fieldMap[f?.__config__?.renderKey] = f;
    }
  });
}
