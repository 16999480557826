<template>
  <div class='JZContainer'>
    <TableWithPagination
      :tableData="targetLists"
      :leftToolbar="tableButtons"
      class="content_block"
      :columnList="columnList"
      :pagination.sync="pagination"
      @size-change="getTargetLists"
      @current-change="getTargetLists"
      :sortInfo.sync="sortInfo"
      @sort-change="getTargetLists">
    </TableWithPagination>
  </div>
</template>

<script>
import {announcementPage, announcementDelete} from '@/api/notice.js';
import TableWithPagination, { TablePaginationMixin } from '@/components/common/TableWithPagination';
export default {
  name: '',
  components: {
    TableWithPagination,
  },
  beforeRouteEnter (to, from, next) {
    next(() => {
      if (['SAAS_NOTICE_ADD', 'SAAS_NOTICE_EDIT', 'SAAS_NOTICE_DETAIL', 'SAAS_NOTICE_AUDIT'].includes(from.name)) {
        window.location.reload();
      }
    });
  },
  mixins: [TablePaginationMixin],
  methods: {
    addNewAd () {
      this.$router.push({
        name: 'SAAS_NOTICE_ADD',
        query: {
          status: 'isAdd',
        },
      });
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTargetLists();
    },
    getTargetLists () {
      let currentParams = {
        title: '',
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
      };
      currentParams.orderBy = [{
        orderByProperty: 'lastUpdateTime',
        orderByType: 'DESC',
      }];
      announcementPage(currentParams).then(res => {
        let currentLists = [];
        if (res.heads.code === 200) {
          currentLists = res.body.list || [];
        } else {
          this.$message.error(res.heads.message);
        }
        this.targetLists = currentLists;
        this.pagination.total = res.body.total;
      });
    },
    handleView (row) {
      this.$router.push({
        name: 'SAAS_NOTICE_DETAIL',
        query: {
          id: row.id,
          status: 'isDetail',
        },
      });
    },
    handleEdit (row) {
      this.$router.push({
        name: 'SAAS_NOTICE_EDIT',
        query: {
          id: row.id,
          status: 'isEditing',
        },
      });
    },
    handleAduit (row) {
      this.$router.push({
        name: 'SAAS_NOTICE_AUDIT',
        query: {
          id: row.id,
          status: 'isAduit',
        },
      });
    },
    handleDelete (info) {
      this.$confirm(`您确定删除[${info.title}]公告吗？`, '删除公告').then(() => {
        announcementDelete({id: info.id}).then((res) => {
          if (res.heads.code === 200) {
            this.handleSearch();
          } else {
            this.$message.error(res.heads.message);
          }
        });
      });
    },
  },
  created () {
    this.handleSearch();
  },
  data () {
    return {
      targetLists: [],
      tableButtons: [
        {
          name: '新增公告',
          status: 'primary',
          size: 'small',
          code: 'Add',
          icon: 'vxe-icon--plus',
          permission: '',
          click: this.addNewAd,
        },
      ],
      columnList: [
        {
          tooltip: true,
          label: '状态',
          minWidth: '100px',
          align: 'center',
          width: '',
          prop: 'statusName',
        },
        {
          tooltip: true,
          label: '公告类型',
          minWidth: '100px',
          width: '',
          prop: 'typeName',
        },
        {
          tooltip: true,
          label: '公告标题',
          minWidth: '150px',
          prop: 'title',
        },
        {
          tooltip: true,
          label: '发布人',
          minWidth: '100px',
          width: '',
          prop: 'createUserName',
        },
        {
          tooltip: true,
          label: '发布时间',
          minWidth: '110px',
          width: '',
          prop: 'releaseTime',
          format: this.$options.filters['date-format'],
        },
        {
          tooltip: true,
          label: '创建时间',
          minWidth: '100px',
          prop: 'createTime',
          format: this.$options.filters['date-format'],
        },
        {
          tooltip: true,
          label: '操作',
          columnOptions: true,
          minWidth: '120px',
          fixed: 'right',
          prop: 'id',
          render: (h, {row}) => {
            let actions = [];
            actions.push(h('span', {
              domProps: {
                className: 'table-action',
              },
              on: {
                click: this.handleView.bind(this, row),
              },
            }, '查看'));
            if (['REJECTED'].includes(row.status)) {
              actions.push(h('span', {
                domProps: {
                  className: 'table-action',
                },
                on: {
                  click: this.handleEdit.bind(this, row),
                },
              }, '编辑'));
            }

            if (['WAIT_AUDIT'].includes(row.status)) {
              actions.push(h('span', {
                domProps: {
                  className: 'table-action',
                },
                on: {
                  click: this.handleAduit.bind(this, row),
                },
              }, '审核'));
            }
            actions.push(h('span', {
              domProps: {
                className: 'table-action',
              },
              on: {
                click: this.handleDelete.bind(this, row),
              },
            }, '删除'));
            return h('div', actions);
          },
        },
      ],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */

</style>
