<!-------------------------------------------
/*
* @Author: Jeason
* @Date: 2022-05-07 15:29:42
 * @Last Modified by: Jeason
 * @Last Modified time: 2022-05-13 17:40:15
    */
 --------------------------------------------->

<template>
  <div>
    <el-dialog
        :title="currentStatus === '1'? '审核回单': '查看回单'"
        :visible.sync="isShow"
        width="700px"
        :append-to-body='true'
        :destroyOnClose='true'
        :close-on-click-modal='false'
    >
        <div class="content content_block">
            <div class="top">
                <div class="left">查看回单：<span :title="detailInfo.fileName">{{detailInfo.fileName}}</span></div>
                <div class="right" v-if="!!detailInfo.fileUrl">
                    <el-button @click="handleView" type='primary'  size="mini" >查看</el-button>
                    <el-button @click="handleDownload" type='primary'  size="mini" >下载</el-button>
                </div>
            </div>
            <upload label=""
              v-if="!!detailInfo.fileUrl"
              v-model.trim="imageList"
              accept="application/pdf,image/png,image/jpeg,image/jpg,application/doc,application/docx"
              :multiple="false"
              :disabled="true"
              :isDescription='isPdf'
              :class="{'isImg': !this.isPdf}"
              size="small"
              :max-size="5"
              :limit="1">
            </upload>
            <span v-else>暂无数据</span>
        </div>
        <span slot="footer" class="dialog-footer " style="text-align:right;" v-if="currentStatus === '1'">
        <el-button @click="handleReject" type='warning'  size="mini" >驳回</el-button>
        <el-button @click="handleApprove" type='primary'  size="mini" >审核通过</el-button>
        <el-button @click="isShow=false" type='default'  size="mini" >取消</el-button>
        </span>
        <span slot="footer" class="dialog-footer " style="text-align:right;" v-else>
        <el-button @click="isShow=false" type='default'  size="mini" >关闭</el-button>
        </span>
    </el-dialog>
    <el-dialog
        title="驳回"
        :visible.sync="isSubShow"
        width="400px"
        :append-to-body='true'
        :destroyOnClose='true'
        :close-on-click-modal='false'
    >
        <div class="content content_block">
            <el-form label-width="120px" :model="JZForm" :rules="JZFormRules" ref="JZForm">
                <el-form-item label="驳回原因：" prop="approveResult">
                    <el-input placeholder="请输入驳回原因" maxlength="100" show-word-limit v-model.trim="JZForm.approveResult"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer " style="text-align:right;" >
            <el-button @click="isSubShow=false" type='default'  size="mini" >取消</el-button>
            <el-button @click="handleSubmitReject" type='primary'  size="mini" >确定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import {getDetailOrderInfo, approvePayVoucher} from '@/api/order';
import upload from '@/components/common/upload';
import FileSaver from 'file-saver';
export default {
  name: 'Trade',
  components: {
    upload,
  },
  methods: {
    initPage () {
      this.getDetailInfo();
    },
    getDetailInfo () {
      getDetailOrderInfo({id: this.id}).then(({body}) => {
        this.isShow = true;
        this.detailInfo = body;
        var index = body.fileName.lastIndexOf('.');
        this.isPdf = body.fileName.substr(index + 1).toUpperCase() === 'PDF';
        this.imageList = [
          {
            url: body.fileUrl,
            attachName: body.fileName,
          },
        ];
      });
    },
    handleReject () {
      this.isSubShow = true;
      this.JZForm = {
        approveResult: '',
      };
      this.$nextTick(() => {
        this.$refs.JZForm.clearValidate();
      });
    },
    handleSubmitReject () {
      // 确认操作
      this.$refs.JZForm.validate(valid => {
        if (valid) {
          let currentParams = {
            id: this.id,
            payStatus: '5',
            approveResult: this.JZForm.approveResult,
          };
          approvePayVoucher(currentParams).then(() => {
            this.$emit('handleReset');
            this.isSubShow = false;
            this.isShow = false;
          });
        }
      });
    },
    handleApprove () {
      this.$confirm('审核通过后，将以此时作为支付时间计算该订单的到期时间，确定审核通过吗？', '审核通过', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        // 确认操作
        let currentParams = {
          id: this.id,
          payStatus: '2',
        };
        approvePayVoucher(currentParams).then(() => {
          this.$emit('handleReset');
          this.isShow = false;
        });
      });
    },
    handleView () {
      window.open(this.detailInfo.fileUrl);
    },
    handleDownload () {
      var oReq = new XMLHttpRequest();
      var URLToPDF = this.detailInfo.fileUrl;
      oReq.open('GET', URLToPDF, true);
      oReq.responseType = 'blob';
      oReq.onload = () => {
        var index = URLToPDF.lastIndexOf('.');
        var fileType = URLToPDF.substr(index + 1);
        var type = {type: 'application/pdf'};
        switch (fileType.toUpperCase()) {
        case 'PNG':
          type = {type: 'image/png'};
          break;
        case 'JPEG':
          type = {type: 'image/jpeg'};
          break;
        case 'JPG':
          type = {type: 'image/jpg'};
          break;
        case 'PDF':
          type = {type: 'application/pdf'};
          break;
        default:
          type = {type: 'application/pdf'};
          break;
        }
        var file = new Blob([oReq.response], type);
        FileSaver.saveAs(file, this.detailInfo.fileName);
      };
      oReq.send();
    },
  },
  data () {
    return {
      detailInfo: {},
      isShow: false,
      currentStatus: '1',
      id: '',
      imageList: [],
      JZForm: {
        approveResult: '',
      },
      isPdf: false,
      isSubShow: false,
      JZFormRules: {
        approveResult: [
          {
            required: true,
            message: '驳回原因必填！',
            trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },
};
</script>

<style scoped lang="scss">
.top{
    display: flex;
    margin-bottom: 5px;
    .left{
        flex: 1;
        display: flex;
        width: calc(100% - 125px);
        span{
          flex: 1;
          text-align: right;
          margin-right: 10px;
          line-height: 24px;
          white-space:nowrap;
          overflow:hidden;
          text-overflow:ellipsis;
        }
    }
  margin-bottom: 10px;
}
/deep/ .el-dialog__header{
    border-bottom: 1px solid #efefef;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item-actions{
  border: 1px solid #efefef;
}
// /deep/ .attach_list.attach_list--small .el-upload-list__item{
//   width: calc(100% - 24px);
//   height: calc(100% - 24px);
//   padding: 12px;
// }
/deep/ .attach_list.attach_list--small{
  &.isImg{
    max-height: 400px;
    overflow-y: auto;
    .el-upload-list__item{
      width: 100%;
      height: 100%;
    }
  }
  border: 1px solid #efefef;
  padding: 12px;
  text-align: center;
}
</style>
