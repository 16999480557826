<template>
  <div>
    <query-form labelWidth="120px" class="content_block" ref="queryForm" @search="handleSearch" @reset="handleReset" :span="8">
      <el-form-item label="公司名称：" >
        <el-input placeholder="请输入公司名称" v-model.trim="query.name"></el-input>
      </el-form-item>
      <el-form-item label="申请人：" >
        <el-input placeholder="请输入申请人" v-model.trim="query.applicantName"></el-input>
      </el-form-item>
      <el-form-item label="租户状态：" >
        <el-select v-model="query.trialStatus" @change="handleSearch">
          <el-option v-for="i in tenantStatusEnumList" :label="i.desc" :key="i.name" :value="i.name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="使用产品：" >
        <product-selector v-model="query.trialProductId" :name.sync="query.trialProductName" @change="handleSearch"></product-selector>
      </el-form-item>
       <el-form-item label="正式激活日期：" >
         <el-date-picker
          v-model="trialActive"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="试用推荐人：" >
        <el-input placeholder="请输入试用推荐人" v-model.trim="query.referrerName"></el-input>
      </el-form-item>
      <el-form-item label="到期日：" >
         <el-date-picker
          v-model="trialExpiry"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="最近登录日：" >
        <el-date-picker
          type="daterange"
          value-format="yyyy-MM-dd"
          v-model="lastLogin"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
    </query-form>
    <table-with-pagination :tableData="tenantList"
      class="content_block"
      :columnList="columnList"
      :pagination.sync="pagination"
      :sortInfo.sync="updateSortInfo"
      @size-change="getTableList"
      @current-change="getTableList"
      @sort-change="getTableList"
    />
  </div>
</template>
<script>
import TenantApi from '@/api/tenant';
import { wrapWithAll } from '@/utils/Enum';
import ProductSelector from '@/components/productSelector';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import TenantStatusEnum, { TenantStatusEnumList } from '@/model/TenantStatusEnum';
import TenantCompanyTypeEnum, { TenantCompanyTypeEnumList } from '@/model/TenantCompanyTypeEnum';
export default {
  name: 'TENANT_PURCHASE_INDEX',
  mixins: [
    TablePaginationMixin,
  ],
  components: {
    ProductSelector,
  },
  data () {
    return {
      tenantStatusEnumList: wrapWithAll(TenantStatusEnumList.filter(t=>t.value === 2 || t.value === 6), ''),
      query: {
        applicantName: '',
        name: '',
        trialProductId: '',
        trialProductName: '',
        trialStatus: '',
        referrerName: '',
        isFormal: true,
      },
      trialActive: [],
      trialExpiry: [],
      lastLogin: [],
      tenantList: [],
      columnList: [
        {
          tooltip: true,
          label: '使用产品',
          minWidth: '100px',
          prop: 'trialProductName',
        },
        {
          tooltip: true,
          label: '公司名称',
          minWidth: '150px',
          prop: 'name',
        },
        {
          tooltip: true,
          label: '公司类型',
          minWidth: '150px',
          prop: 'type',
          format: val=> TenantCompanyTypeEnumList[TenantCompanyTypeEnum[val]] ? TenantCompanyTypeEnumList[TenantCompanyTypeEnum[val]].desc : '',
        },
        {
          tooltip: true,
          label: '试用申请人',
          minWidth: '150px',
          prop: 'applicantName',
        },
        {
          tooltip: true,
          label: '联系电话',
          minWidth: '150px',
          prop: 'applicantPhoneNumber',
        },
        {
          tooltip: true,
          label: '最近登录时间',
          minWidth: '150px',
          prop: 'lastLoginTime',
          format: this.$options.filters['date-format'],
        },
        {
          tooltip: true,
          label: '正式激活时间',
          minWidth: '150px',
          prop: 'trialActiveTime',
          sortable: true,
          format: val=>this.$options.filters['date-format'](val),
        },
        {
          tooltip: true,
          label: '租户到期日',
          minWidth: '150px',
          prop: 'trialExpiryTime',
          format: val=>this.$options.filters['date-format'](val, 'YYYY-MM-DD'),
        },
        {
          tooltip: true,
          label: '试用推荐人',
          minWidth: '120px',
          prop: 'referrerName',
        },
        {
          tooltip: true,
          label: '租户状态',
          minWidth: '150px',
          prop: 'trialStatus',
          format: val=> TenantStatusEnumList[TenantStatusEnum[val]].desc,
        },
        {
          label: '操作',
          minWidth: '180px',
          fixed: 'right',
          render: (h, {row}) => {
            let actions = [];
            actions.push(<span v-auth={'saas_trialTenantManage:query'} class='cursor-pointer text-primary margin-left' onClick={this.handleActionClick.bind(this, row, 'check')}>查看</span>);
            if (row.bindStatus === '2' && (row.trialStatus === TenantStatusEnum[1] || row.trialStatus === TenantStatusEnum[6])) {
              actions.push(<span v-auth={'saas_trialTenantManage:bind'} class='cursor-pointer text-primary margin-left' onClick={this.handleActionClick.bind(this, row, 'bind')}>推送采购平台</span>);
            }
            return <div>{...actions}</div>;
          },
        },
      ],
    };
  },
  methods: {
    handleActionClick ({id: tenantId }, action) {
      if (action === 'check') {
        this.$router.push({
          name: 'TENANT_PURCHASE_DETAIL',
          query: {
            id: tenantId,
          },
        });
      } else if (action === 'bind') {
        TenantApi.bind(tenantId).then(()=>{
          this.$message.success('推送成功');
          this.handleSearch();
        });
      }
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    handleReset () {
      this.query = {
        applicantName: '',
        name: '',
        trialProductId: '',
        trialProductName: '',
        trialStatus: '',
        referrerName: '',
        isFormal: true,
      };
      this.trialActive = [];
      this.trialExpiry = [];
      this.lastLogin = [];
      this.handleSearch();
    },
    getTableList () {
      let param = {...this.query, ...this.updateSortInfo, pageSize: this.pagination.pageSize, pageNum: this.pagination.currentPage};
      if (this.trialActive && this.trialActive.length > 1) {
        param.trialActiveStartTime = this.trialActive[0];
        param.trialActiveEndTime = this.trialActive[1];
      }
      if (this.trialExpiry && this.trialExpiry.length > 0) {
        param.trialExpiryStartTime = this.trialExpiry[0];
        param.trialExpiryEndTime = this.trialExpiry[1];
      }
      if (this.lastLogin && this.lastLogin.length > 0) {
        param.lastLoginStartTime = this.lastLogin[0];
        param.lastLoginEndTime = this.lastLogin[1];
      }
      TenantApi.getTenantList(param).then(res => {
        this.tenantList = res.body.list;
        this.pagination.total = res.body.total;
      });
    },
  },
};
</script>
