<template>
    <el-form label-width="120px" class="content_block" :model="form" :rules="formRules" ref="feedbackForm">
        <el-form-item label="客户名称：">{{form.tenantName || '--'}}</el-form-item>
        <el-form-item label="意见反馈模块：">{{form.moduleDesc || '--'}}</el-form-item>
        <el-form-item label="手机号：">{{form.mobilephone || '--'}}</el-form-item>
        <el-form-item label="问题描述：">{{form.remark || '--'}}</el-form-item>
        <el-form-item label="问题图片：" v-if="imgUrlLists.length===0">
          无
        </el-form-item>
        <el-form-item label="问题图片：" v-else>
          <el-image :preview-src-list='imgUrlLists' :key="index" v-for="(item, index) in form.fileList" :z-index='index' fit="fill"  style="width: 60px; height: 60px;vertical-align: top;margin-right:10px" :src='item.url' ></el-image>
        </el-form-item>
        <el-form-item v-if="isReadonly&&form.handleResult" label="处理结果：">
          {{form.handleResult || '--'}}
        </el-form-item>
        <el-form-item v-if="!isReadonly" label="处理结果：" prop="handleResult">
          <el-input  type="textarea" v-model="form.handleResult" placeholder="请输入处理结果"></el-input>
        </el-form-item>
        <el-form-item v-if="isReadonly&&this.form.status==='3'" label="处理人：">{{form.handlerUserName || '--'}}</el-form-item>
        <el-form-item v-if="isReadonly&&this.form.status==='3'" label="处理时间：">{{form.handleTime | date-format}}</el-form-item>
        <el-form-item>
            <el-button @click="handleCancel">取消</el-button>
            <el-button  v-if="!isReadonly" type="primary" @click="handleProcess">处理</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
import { getFeedbackDetail, processFeedback } from '@/api/feedback';
export default {
  name: 'feedback_detail',
  props: {
    id: String,
    readonly: Boolean,
  },
  data () {
    return {
      form: {
        id: '',
        handleResult: '',
      },
      formRules: {
        handleResult: [
          { required: true, message: '请输入处理结果', trigger: 'blur'},
          { max: 100, message: '处理结果最大100个字符', trigger: 'blur' },
        ],
      },
      imgUrlLists: [],
    };
  },
  computed: {
    isReadonly () {
      return this.readonly;
    },
  },
  methods: {
    getFeedbackDetail () {
      this.id && getFeedbackDetail(this.id).then(t => {
        this.form = t.body;
        this.imgUrlLists = this.form.fileList.map(item => item.url);
      });
    },
    handleCancel () {
      this.$router.go(-1);
    },
    handleProcess () {
      this.$refs.feedbackForm.validate(valid=>{
        if (valid) {
          processFeedback({...this.form}).then(this.handleCancel);
        }
      });
    },
  },
  created () {
    this.getFeedbackDetail();
  },
};
</script>
