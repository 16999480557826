import axios from 'axios';
import { Encrypt, Decrypt } from '@/utils/secret';
import router from '../router';
import { Message, Loading } from 'element-ui';
let baseURL = '/api';
// 环境的切换
baseURL = process.env.VUE_APP_HOST;
const server = axios.create({
  baseURL: baseURL,
  timeout: 60000,
  headers: {
    'content-type': 'application/json',
  },
});
let requestCount = 0;
let loading;
// 请求拦截器
server.interceptors.request.use(config => { // 拦截请求，做统一处理
  const token = localStorage.getItem('token');
  if (config.method.toUpperCase() === 'POST') {
    config.data = config.data || {};
  }
  config.data = Encrypt(JSON.stringify(config.data || {}));
  // 在每个http header都加上token
  config.headers.Authorization = 'bearer ' + token;
  if (requestCount === 0) {
    loading = Loading.service({
      lock: true,
      text: '加载中...',
      spinner: 'el-icon-loading',
    });
  }
  requestCount++;
  return config;
}, err => { // 失败
  return Promise.reject(err);
});
// 响应拦截器
server.interceptors.response.use(response => { // 拦截响应，做统一处理
  requestCount--;
  if (requestCount === 0) {
    loading.close();
  }
  if (typeof response.data === 'string' && response.config.responseType !== 'blob') {
    response.data = JSON.parse(Decrypt(response.data));
  }
  if (response.config.url === 'http://upload.qiniup.com') {
    return response.data;
  } else if (response.config?.responseType === 'blob') {
    return { data: response.data, headers: response.headers };
  } else if (response.data.heads && ([200, 6001, 2500].includes(response.data.heads.code))) {
    return response.data;
  } else if (response.data.heads && (response.data.heads.code === 4012 || response.data.heads.code === 506)) {
    localStorage.clear();
    router.push({
      name: 'Login',
      query: {
        redirect: router.currentRoute.fullPath,
      },
    });
    return Promise.reject(new Error('token已过期'));
  } else {
    Message({
      showClose: true,
      message: response.data.heads.message,
      type: 'error',
    });
    return Promise.reject(response.data.heads.message);
  }
}, err => { // 无响应时的处理
  requestCount--;
  if (requestCount === 0) {
    loading.close();
  }
  let defaultMsg = '系统异常';
  if (err.config.url === 'http://127.0.0.1:10011/xiaozi-print/print/getPrintList') {
    defaultMsg = '请开启打印服务！';
  }
  if (err.response && err.response.data && err.response.data.heads) {
    defaultMsg = err.response.data.heads.message || defaultMsg;
  }
  Message({
    showClose: true,
    message: defaultMsg,
    type: 'error',
  });
  return Promise.reject((err && err.response && err.response.status) || defaultMsg);
});
export default server;
