import server from '@/request/encryptRequest';

export const announcementAdd = (data) => {
  return server.post('/xiaozi-saas/wireless/announcement/add', data);
};

export const announcementAudit = (data) => {
  return server.post('/xiaozi-saas/wireless/announcement/audit', data);
};

export const announcementDelete = (data) => {
  return server.post('/xiaozi-saas/wireless/announcement/delete', data);
};

export const announcementDetail = (data) => {
  return server.post('/xiaozi-saas/wireless/announcement/detail', data);
};

export const announcementPage = (data) => {
  return server.post('/xiaozi-saas/wireless/announcement/page', data);
};

export const announcementUpdate = (data) => {
  return server.post('/xiaozi-saas/wireless/announcement/update', data);
};
