<template>
  <div class="industry-capacity-detail">
    <content-block>
      <content-title slot="title" justify="space-between">
        <span>行业能力{{ auditFlag ? '审核' : '详情' }}</span>
      </content-title>
      <el-descriptions :column="3" size="normal" :labelStyle="nw" style="padding: 0 15px;">
        <el-descriptions-item label="企业名称">{{ tenantDetail.tenantName | commonFormat }}</el-descriptions-item>
        <el-descriptions-item label="提交人">{{ tenantDetail.submitUserName | commonFormat }}</el-descriptions-item>
        <el-descriptions-item label="联系方式">{{ tenantDetail.submitPhone | commonFormat }} / {{ tenantDetail.submitEmail | commonFormat}}</el-descriptions-item>
        <el-descriptions-item label="提交时间">{{ tenantDetail.submitTime | formatDateTime }}</el-descriptions-item>
      </el-descriptions>
      <el-card>
        服务地区
        <div class="tag-wrapper">
          <el-tag v-if="tenantDetail.itemList.filter(v => v.type === 1).length === 34">全国</el-tag>
          <el-tag v-else v-for="(item, i) in tenantDetail.itemList.filter(v => v.type === 1)" type="success"
            :key="'area-'+i">
            {{ item.capacityValue }}
          </el-tag>
        </div>
      </el-card>
      <el-card class="gap-top-10">
        服务设备品牌
        <div class="tag-wrapper">
          <el-tag v-for="(item, i) in tenantDetail.itemList.filter(v => v.type === 2)" type="success"
            :key="'brand-'+i">
            {{ item.capacityValue }}
          </el-tag>
        </div>
      </el-card>
      <el-card class="gap-top-10">
        服务设备分类
        <div class="tag-wrapper">
          <el-tag v-for="(item, i) in tenantDetail.itemList.filter(v => v.type === 4)" type="success"
            :key="'device-'+i">
            {{ item.capacityValue }}
          </el-tag>
        </div>
      </el-card>
      <el-card class="gap-top-10">
        能力标签
        <div class="tag-wrapper">
          <el-tag v-for="(item, i) in tenantDetail.itemList.filter(v => v.type === 3)" type="success"
            :key="'tags-'+i">
            {{ item.capacityValue }}
          </el-tag>
        </div>
      </el-card>
      <el-card class="gap-top-10">
        证明材料
        <div class="tag-wrapper">
          <template v-if="tenantDetail.credentialMaterialList && tenantDetail.credentialMaterialList.length">
            <div v-for="(cert, i) in tenantDetail.credentialMaterialList" :key="'cert-'+i" style="display: inline-block; margin-right: 20px;">
              <a :href="cert.url" target="_blank">{{ cert.name }}</a>
            </div>
          </template>
        </div>
      </el-card>
    </content-block>
    <bottom-panel>
      <label v-if="query.id && auditFlag && tenantDetail.auditStatus === 'WAIT'" style="float: left;">
        审批意见:
        <el-input placeholder="请输入审批意见" v-model.trim="approveMsg" style="width: 500px"></el-input>
      </label>
      <el-button @click="cancel" style="margin-left: 10px;">取消</el-button>
      <template v-if="query.id && auditFlag && tenantDetail.auditStatus === 'WAIT'">
        <el-button type="danger" @click="doApprove('REJECT')">驳回</el-button>
        <el-button type="primary" @click="doApprove('PASS')">通过</el-button>
      </template>
    </bottom-panel>
  </div>
</template>
<script>
import * as dayjs from 'dayjs';
import { industryCapacityDetail, industryCapacityAudit } from '@/api/industryCapability';
export default {
  name: 'INDUSTRY_CAPACITY_DETAIL',
  data () {
    return {
      nw: {
        'word-break': 'keep-all',
      },
      query: {},
      auditFlag: false,
      tenantDetail: {
        auditStatus: null,
        itemList: [],
        credentialMaterialList: [],
      },
      approveMsg: '',
    };
  },
  filters: {
    formatDateTime: val => {
      return val ? dayjs(val).format('YYYY-MM-DD HH:mm:ss') : '--';
    },
    commonFormat: val => {
      return val || '--';
    },
  },
  methods: {
    cancel () {
      this.$router.go(-1);
    },
    doApprove (flag) {
      if (flag === 'REJECT' && !this.approveMsg) {
        this.$message.warning('审批意见不能为空');
        return;
      }
      industryCapacityAudit({
        id: this.query.id,
        auditStatus: flag,
        auditReason: this.approveMsg,
      }).then(() => {
        this.$message.success('操作成功');
        this.tenantDetail.auditStatus = flag;
        setTimeout(() => {
          this.$router.push({
            name: 'INDUSTRY_CAPACITY_INDEX',
            query: {
              timeStamp: new Date().getTime(),
            },
          });
        }, 2000);
      });
    },
    getIndustryCapacityDetail () {
      if (!this.query.tenantId) return;
      industryCapacityDetail({ tenantId: this.query.tenantId }).then(res => {
        this.tenantDetail = res.body || {};
      });
    },
  },
  mounted () {
    this.query = this.$route.query;
    this.auditFlag = this.query.action === 'audit' || false;
    this.getIndustryCapacityDetail();
  },
};
</script>
<style lang="scss" scoped>
.industry-capacity-detail {
  .gap-top-10 {
    margin-top: 10px;
  }

  .tag-wrapper {
    padding: 5px 0;
    line-height: 30px;
  }
  .el-tag {
    margin-right: 10px;
  }
}
</style>
