import server from '../request';

export const cooperationEnterprisePageList = (data) => {
  return server.post('/xiaozi-saas/wireless/cooperationEnterprise/pageList', data);
};

export const cooperationEnterpriseClose = (data) => {
  return server.post('/xiaozi-saas/wireless/cooperationEnterprise/close', data);
};

export const cooperationEnterpriseOpen = (data) => {
  return server.post('/xiaozi-saas/wireless/cooperationEnterprise/open', data);
};

export const cooperationSubscriptionPageList = (data) => {
  return server.post('/xiaozi-saas/wireless/cooperationSubscription/pageList', data);
};

export const cooperationSubscriptionCancel = (data) => {
  return server.post('/xiaozi-saas/wireless/cooperationSubscription/cancel', data);
};
