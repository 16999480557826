<template>
  <div>
    <query-form labelWidth="110px" class="content_block" ref="queryForm" @search="handleSearch" @reset="handleReset">
      <el-form-item label="维修商名称：">
        <el-input placeholder="请输入维修商名称" v-model.trim="query.name"></el-input>
      </el-form-item>
      <el-form-item label="渠道名称：">
        <el-input placeholder="请输入渠道名称" v-model.trim="query.channelName"></el-input>
      </el-form-item>
    </query-form>
    <table-with-pagination
      :tableData="orderList"
      class="content_block"
      :showRightToolbar="false"
      :columnList="columnList"
      :showFooter='true'
      :footerData="footerData"
      :pagination.sync="pagination"
      @size-change="getTableList"
      @current-change="getTableList"
      :getRowClass='getRowClassName'
    />
  </div>
</template>
<script>
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import { getwirelessReport } from '@/api/report';
import { mapGetters } from 'vuex';

export default {
  name: 'REPAIRMAN',
  mixins: [TablePaginationMixin],
  computed: {
    ...mapGetters(['actionPermissionMap']),
  },
  data () {
    return {
      query: {
        name: '',
        channelName: '',
      },
      orderList: [],
      footerData: [],
      columnList: [
        {
          tooltip: true,
          label: '维修商名称',
          minWidth: '100px',
          prop: 'name',
          fixed: 'left',
          render: (h, { row }) => {
            if (this.actionPermissionMap['saas_report:detail']) {
              return (
                <span class='cursor-pointer text-primary' onClick={this.handleActionClick.bind(this, row)}>
                  {row.name}
                </span>
              );
            } else {
              return <span>{row.name}</span>;
            }
          },
        },
        {
          tooltip: true,
          label: '医院添加数量（家）',
          minWidth: '100px',
          prop: 'totalCustomerNum',
        },
        {
          tooltip: true,
          label: '已支付医院数量（家）',
          minWidth: '100px',
          prop: 'paymentCustomerNum',
        },
        {
          tooltip: true,
          label: '工单总数（单）',
          minWidth: '100px',
          prop: 'totalWorkOrderNum',
          format: val => {
            return String(val).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
          },
        },
        {
          tooltip: true,
          label: '所属渠道',
          minWidth: '100px',
          prop: 'channelName',
        },
      ],
    };
  },
  created () {
    this.handleSearch();
  },
  methods: {
    async getTableList () {
      let param = { ...this.query, pageSize: this.pagination.pageSize, pageNum: this.pagination.currentPage };
      const res = await getwirelessReport(param);
      let info = res.body;
      if (res.heads.code === 200) {
        this.orderList = info.list;
        this.pagination.total = info.total;
      }
      let currentLists = [];
      // if (pageInfo.list.length) {
      currentLists.push({
        checkTitle: '页合计',
        totalCustomerNum: this.$options.filters['formatThousand'](info.whenPageHospitalsNum),
        paymentCustomerNum: this.$options.filters['formatThousand'](info.whenPagePaymentHospitalsNum),
        totalWorkOrderNum: this.$options.filters['formatThousand'](info.whenPageWorkOrdersNum),
      });
      currentLists.push({
        checkTitle: '总计',
        totalCustomerNum: this.$options.filters['formatThousand'](info.totalHospitalsNum),
        paymentCustomerNum: this.$options.filters['formatThousand'](info.totalPaymentHospitalsNum),
        totalWorkOrderNum: this.$options.filters['formatThousand'](info.totalWorkOrdersNum),
      });
      // }
      this.$nextTick(() =>{
        this.footerData = this.orderList.length ? currentLists : [];
      });
    },
    handleActionClick ({ id }) {
      this.$router.push({
        name: 'REPORT_REPAIRMAN_HOSPITAL_DETAIL',
        query: { id },
      });
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    handleReset () {
      this.query = {
        name: '',
        channelName: '',
      };
      this.handleSearch();
    },
    getRowClassName (row) {
      let isSumary = !!row.checkTitle;
      return isSumary ? 'row-red' : '';
    },
  },
};
</script>
<style scoped lang='scss'>
  /deep/.vxe-footer--row .vxe-footer--column{
    background-image: linear-gradient(#e8eaec,#e8eaec),linear-gradient(#e8eaec,#e8eaec);
    background-repeat: no-repeat;
    background-size: 1px 100%,100% 1px;
    background-position: 100% 0,100% 100%;
  }
</style>
