<template>
  <div class="order_detail">
    <template v-if="id">
      <content-block title="工单进度">
        <div class="order_steps">
          <div  v-for="(s,index) in stepList"
            :key="index"
            :class="{
              order_step_item:true,
              actived:currentStep === (index +1),
              finished:currentStep>(index+1)
            }" >
            <div class="order_step_icon">
              <i v-if="(index+1) < currentStep" class="el-icon-check"></i>
              <span v-else>{{index+1}}</span>
            </div>
            <div class="order_step_title">{{s.title}}</div>
            <div class="order_step_line"></div>
          </div>
        </div>
        <div v-if="orderModel.dynamicInformList && orderModel.dynamicInformList[0]" class="order_progress_sum d-flex flex-row justify-content-between">
          <div class="flex-fill d-flex flex-column justify-content-around ">
            <div class=" d-flex flex-row align-items-center">
              <span class="order_progress_status">{{orderModel.dynamicInformList[0].actionDesc}}</span>
              {{orderModel.dynamicInformList[0].operateTime | date-format}}
            </div>
            <span class="order_progress_desc">{{orderModel.dynamicInformList[0].content}}</span>
          </div>
          <div class=" d-flex flex-row align-items-center cursor-pointer" @click="drawerVisible=true">
            <span>查看动态信息</span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </content-block>
    </template>
    <parser ref="newOrderModelRef"
      v-if="isLoadedTemplate"
      :form-conf="schemaDesc"
      :tenantId="tenantId"
      :formModel="orderModel">
    </parser>
     <bottom-panel>
      <el-button type="primary" plain class="mr-1" @click="handleClose">关闭</el-button>
    </bottom-panel>
    <el-drawer
      title="动态信息"
      :visible.sync="drawerVisible">
       <el-timeline class="content_block">
        <el-timeline-item
          v-for="(activity, index) in orderModel.dynamicInformList"
          :key="index"
          hide-timestamp
          :type="index===0?'primary':''"
          placement="top">
          <div class="order_progress_item">
            <div>
              <span>{{activity.actionDesc}}</span>
              {{dateFormat(activity.operateTime)}}
            </div>{{activity.content}}
          </div>
        </el-timeline-item>
      </el-timeline>
    </el-drawer>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import OrderStatusEnum from '@/enums/OrderStatusEnum';
import { getQueryWorkOrderTemplate, getQueryWorkOrderDetail} from '@/api/report';
import Parser from '@/components/form-generator/parser/index.js';
import { getDefaultValueByTag } from '@/components/form-generator/utils/component.js';
export default {
  name: 'ORDER_EDIT',
  components: {
    Parser,
  },
  props: {
    id: String,
    templateCode: {
      type: String,
    },
    tenantId: {
      type: String,
      required: true,
    },
  },
  watch: {
    assignDialogVisible () {
      if (!this.assignDialogVisible) {
        this.assignForm = {
          principalMsgId: null,
          principalMsg: null,
        };
      }
    },
  },
  data () {
    return {
      currentInfo: {
        customizeBusinessScenarioCode: '',
        deviceId: '',
      },
      stepList: [
        {
          title: '待指派',
        },
        {
          title: '已指派',
        },
        {
          title: '已接单',
        },
        {
          title: '维修中',
        },
        {
          title: '已签字',
        },
        {
          title: '已完成、待评价',
        },
        {
          title: '已评价',
        },
      ],
      drawerVisible: false,
      assignDialogVisible: false,
      assignForm: {
        principalMsgId: null,
        principalMsg: null,
      },
      assignFormRules: {
        principalMsg: [
          {required: true, message: '请选择指派人', type: 'object', trigger: ['change', 'blur']},
        ],
      },
      principalMsg: null,
      dateFormat: this.$options.filters['date-format'],
      isLoadedTemplate: false,
      isAssign: false,
      orderModel: {},
      templateType: null,
      dialogVisible: false,
      schemaDesc: {},
    };
  },
  methods: {
    handleClose () {
      this.$router.go(-1);
    },
    getViewModel (nodeList) {
      nodeList && nodeList.forEach(node=>{
        if (node?.__config__?.layout === 'groupRowFormItem') {
          this.getViewModel(node?.__config__?.children);
        } else {
          this.$set(this.orderModel, node.__config__.renderKey, getDefaultValueByTag(node));
        }
      });
    },
    getTemplateInfo (param) {
      return getQueryWorkOrderTemplate({
        ...param,
        tenantId: this.tenantId,
      }).then(rs => {
        let isDisabled = !this.actionPermissionMap['workOrder:edit'];
        let formConf = rs.body?.customizeBusinessProcessResDTO?.customizeLayoutResDTO?.schemaDesc || {};
        formConf.disabled = isDisabled;
        this.schemaDesc = formConf;
        this.templateType = rs.body?.customizeBusinessTypeResDTO;
      });
    },
    getOrderDetail () {
      getQueryWorkOrderDetail({
        workOrderId: this.id,
        tenantId: this.tenantId,
      }).then(res=>{
        this.currentInfo.deviceId = res.body?.deviceMsg?.id || '';
        this.currentInfo.customizeBusinessScenarioCode = res.body?.typeMsg?.customizeBusinessScenarioCode || '';

        this.getTemplateInfo({
          customizeBusinessTypeId: res.body?.typeMsg?.id,
          status: res.body?.status,
        }).then(()=>{
          this.getViewModel(this.schemaDesc?.fields, res.body);
          Object.keys(res.body).forEach(key=>{
            if (res.body[key]) {
              this.$set(this.orderModel, key, res.body[key]);
            }
          });
          this.schemaDesc.disabled = true;
          this.isLoadedTemplate = true;
        });
      });
    },
  },
  created () {
    this.getOrderDetail();
  },
  computed: {
    ...mapGetters(['actionPermissionMap']),
    currentStep () {
      if (this.orderModel.evalStatus === OrderStatusEnum.待评价) {
        return 6;
      }

      if (this.orderModel.evalStatus === OrderStatusEnum.已评价) {
        return 7;
      }

      switch (this.orderModel.status) {
      case OrderStatusEnum.待指派:
        return 1;
      case OrderStatusEnum.已指派:
        return 2;
      case OrderStatusEnum.已接单:
        return 3;
      case OrderStatusEnum.维修中:
        return 4;
      case OrderStatusEnum.已签字:
        return 5;
      default:
        return 0;
      }
    },
    fieldMap () {
      let fieldMap = {};
      let flatterField = (nodeList) => {
        nodeList && nodeList.forEach(node=>{
          if (node?.__config__?.layout === 'groupRowFormItem') {
            flatterField(node?.__config__?.children);
          } else {
            fieldMap[node.__config__.renderKey] = node;
          }
        });
      };
      flatterField(this.schemaDesc?.fields);
      return fieldMap;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-row {
  display: flex;
  flex-wrap: wrap;
  .el-col {
    flex-shrink: 0;
  }
}
.order_detail {
  /deep/.content_block {
    margin-bottom: 18px;
  }
}
.order_steps {
  display: flex;
  flex-direction: row;

  .order_step_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    color: rgba(36, 40, 50, 0.4);
    font-weight: bold;

    .order_step_icon {
      position: relative;
      z-index: 1;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      box-sizing: border-box;
      background: #FFF;
      border: 1px solid rgba(36, 40, 50, 0.4);
      transition: .15s ease-out;
      margin-right: 8px;
    }

    .order_step_line {
      width: 40px;
      height: 1px;
      background: rgba(36, 40, 50, 0.4);
      margin: 0 8px;
    }

    &.finished {
      color: rgba(36, 40, 50, 0.8);
      .order_step_icon {
        border-color: $primary-color;
        color:  $primary-color;
      }

      .order_step_line{
        background: $primary-color;
      }
    }

    &.actived {
      .order_step_icon {
        border-color: $primary-color;
        background: $primary-color;
        color: white;
      }
    }

    &:last-child {
       .order_step_line{
        background:transparent;
      }
    }
  }
}
.order_progress_sum {
  margin-top: 24px;
  width: 480px;
  height: 64px;
  background: rgba(36, 40, 50, 0.06);
  border-radius: 4px;
  color: rgba(36, 40, 50, 0.6);
  font-size: 12px;
  line-height: 1;
  padding: 12px;
  box-sizing: border-box;

  .order_progress_status {
    font-size: 16px;
    margin-right: 16px;
    color: rgba(36, 40, 50, 0.8);
  }
}

.order_progress_item{
  font-size: 12px;
  color: rgba(36, 40, 50, 0.7);
  line-height: 16px;
  vertical-align: middle;

  >div{
    margin-bottom: 8px;
    >span{
      font-size: 14px;
      font-weight: bold;
      margin-right: 12px;
    }
  }
}

.mt_item_cell {
  margin: -8px -10px;

  &>div+div{
    border-top:1px solid #ebeef5;
  }

  &>div{
    line-height: 40px;

    &>div:first-child {
      width:200px;
    }
    &>div:last-child {
      padding-left: 16px;
      border-left:1px solid #ebeef5;
    }
  }
}
</style>
