<template>
  <div  :class="{'role-tree-node':true,'has-children':nodeData[treeNodeProps.children] && nodeData[treeNodeProps.children][0],'no-children':!nodeData[treeNodeProps.children] || !nodeData[treeNodeProps.children][0]}">
    <div class="role-tree-title">
      <span>
        <el-checkbox :disabled="readonly" :value="nodeData.isChecked" @change="handleNodeCheck(nodeData[treeNodeProps.key],$event)">
          <span :class="{'action_resource':nodeData.type===3,'role_resource':true}">{{ nodeData[treeNodeProps.name] }}</span>
        </el-checkbox>
      </span>
    </div>
    <div
      v-if="nodeData[treeNodeProps.children]"
      class="role-tree-children">
      <roleTree
        :roleMap="roleMap"
        :readonly="readonly"
        v-for="item in nodeData[treeNodeProps.children].filter(t=>isNotEmpty(t[treeNodeProps.children]))"
        :key="item[treeNodeProps.key]"
        :node-data="item"
        :tree-node-props="treeNodeProps"
        @nodeClick="val => $emit('nodeClick', val)"
      />
      <div class="role-tree-node  no-children" v-if="nodeData[treeNodeProps.children].some(t=>!isNotEmpty(t[treeNodeProps.children]))">
        <div class="role-tree-title">
          <span :key="i[treeNodeProps.key]" v-for="i in nodeData[treeNodeProps.children].filter(t=>!isNotEmpty(t[treeNodeProps.children]))">
            <el-checkbox :disabled="readonly" :value="i.isChecked" @change="handleNodeCheck(i[treeNodeProps.key],$event)">
              <span :class="{'action_resource':i.type===3,'role_resource':true}">{{i[treeNodeProps.name]}}</span>
            </el-checkbox>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'role_tree_node',
  props: {
    nodeData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    treeNodeProps: {
      type: Object,
      default: function () {
        return {
          key: 'id',
          name: 'name',
          children: 'children',
          parentId: 'parentId',
        };
      },
    },
    roleMap: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    roleTree: () => import('./treeNode.vue'),
  },
  methods: {
    isNotEmpty (array) {
      return array && array[0];
    },
    handleNodeCheck (nodeId, value) {
      let currentNode = this.roleMap[nodeId];
      let setAfterNodeChecked = (node, isChecked) => {
        this.$set(node, 'isChecked', isChecked);
        node.children && node.children.forEach(n => {
          setAfterNodeChecked(n, isChecked);
        });
      };
      let setPreNodeChecked = (node, isChecked) => {
        let parentNode = this.roleMap[node[this.treeNodeProps.parentId]];
        if (!parentNode) return;
        if (isChecked) {
          // 若选中，需要递归往上确认父节点是否需要选中
          this.$set(parentNode, 'isChecked', isChecked);
          setPreNodeChecked(parentNode, isChecked);
        } else {
          // 若取消选中，需要判断父节点是否选中，进行取消动作
          if (parentNode.isChecked && parentNode.children.every(t => !t.isChecked)) {
            this.$set(parentNode, 'isChecked', isChecked);
            setPreNodeChecked(parentNode, isChecked);
          }
        }
      };
      if (currentNode) {
        setAfterNodeChecked(currentNode, value);
        // 按钮操作权限不影响菜单资源选中
        if (currentNode.type !== 3) {
          setPreNodeChecked(currentNode, value);
        }
      }
    },
  },
  data () {
    return {};
  },
};
</script>
<style lang="scss">
.role-tree-node {
  font-size: 14px;
  color: rgb(96, 98, 102);
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;

  &.no-children {
    border-bottom: 1px solid rgba(36, 40, 36, 0.1);
    border-right:1px solid rgba(36, 40, 36, 0.1);
  }

  >.role-tree-children {
      display: flex;
      flex-direction: column;
      position: relative;
      flex: 1;
  }

  >.role-tree-title {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-left: 12px;
      line-height: 32px;
      min-width: 160px;
      // box-shadow: 1px 0 0 0 rgba(36, 40, 36, 0.1),
      //         0 1px 0 0 rgba(36, 40, 36, 0.1),
      //         1px 1px 0 0 rgba(36, 40, 36, 0.1), /* 修复没有角 */
      //         1px 0 0 0 rgba(36, 40, 36, 0.1) inset,
      //         0 1px 0 0 rgba(36, 40, 36, 0.1) inset;

      >span{
          display: inline-block;
          margin-right: 8px;
      }
  }

  &.has-children > .role-tree-title  {
    border-right: 1px solid rgba(36, 40, 36, 0.1);
    border-bottom:1px solid rgba(36, 40, 36, 0.1) ;
  }

  .role_resource {
    font-weight: bolder;
    color: rgb(96, 98, 102);

    &.action_resource {
      color: gray;
      font-weight: normal;
      font-size: 12px;
    }
  }
}
</style>
