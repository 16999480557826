<template>
  <div class="content_block">
    <query-form label-wdith="100px" @search="handleSearch" @reset="handleReset" labelWidth="100px">
      <el-form-item label="标签名称：">
        <el-input v-model="query.dataTagName" placeholder="请输入标签名称"></el-input>
      </el-form-item>
      <el-form-item label="功能名称：">
        <el-input v-model="query.dataFunctionName" placeholder="请输入功能名称"></el-input>
      </el-form-item>
    </query-form>
    <table-with-pagination :tableData="tagList"
      :showRightToolbar="false"
      :columnList="columnList"
      :pagination.sync="pagination"
      :sortInfo.sync="updateSortInfo"
      @size-change="getTableList"
      @current-change="getTableList"
      @sort-change="getTableList"
    />
    <el-dialog width="600px" title="关联源数据编辑" :visible.sync="dialogVisible">
      <content-block>
        <div class="margin-bottom">标签名称：{{currentTagName}}</div>
        <table-with-pagination :tableData="datascopeRuleList"
          :showPager="false"
          :showRightToolbar="false"
          :columnList="datascopeRuleColumns"
        />
      </content-block>
      <span slot="footer">
        <el-button @click="dialogVisible=false">取消</el-button>
        <el-button type="primary" @click="handleSaveDatascopeRule">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ProductApi from '@/api/product';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
export default {
  name: 'PRODUCT_DATASCOPE',
  mixins: [
    TablePaginationMixin,
  ],
  props: {
    id: String,
  },
  data () {
    return {
      query: {
        dataTagName: '',
        dataFunctionName: '',
      },
      tagList: [],
      columnList: [
        {
          tooltip: true,
          label: '功能名称',
          minWidth: '100px',
          prop: 'dataFunctionName',
        },
        {
          tooltip: true,
          label: '标签名称',
          minWidth: '100px',
          prop: 'name',
        },
        {
          tooltip: true,
          label: '关联源数据',
          minWidth: '100px',
          render: (h, {row})=>{
            return h('span', {
              class: `${row.code !== 'THIS_COMPANY' ? 'cursor-pointer' : ''} text-primary`,
              on: {
                click: ()=>{
                  if (row.code === 'THIS_COMPANY') {
                    return;
                  }
                  this.currentTagName = row.name;
                  this.getDatascopeDetail(row.id);
                },
              },
            }, row.dataRulesStr);
          },
        },
        {
          tooltip: true,
          label: '启用状态',
          minWidth: '100px',
          prop: 'enableFlag',
          render: (h, {row})=>{
            return <el-switch v-model={row.enableFlag} onChange={this.handleEnableChanged.bind(this, row)} />;
          },
        },
      ],
      dialogVisible: false,
      datascopeRuleList: [],
      datascopeRuleColumns: [
        {
          tooltip: true,
          label: '源数据名称',
          minWidth: '100px',
          prop: 'name',
        },
        {
          tooltip: true,
          label: '条件关系',
          minWidth: '100px',
          render: (h, {row})=>{
            return (<el-select v-model={row.conditionalRelation}>
              <el-option value='OR'></el-option>
              <el-option value='AND'></el-option>
            </el-select>);
          },
        },
      ],
      currentTagName: '',
    };
  },
  methods: {
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    handleReset () {
      this.query = {
        dataTagName: '',
        dataFunctionName: '',
      };
      this.handleSearch();
    },
    handleEnableChanged (row, enableFlag) {
      ProductApi.switchDatascopeFlag({id: row.id, enableFlag}).finally(() => {
        this.getTableList();
      });
    },
    getTableList () {
      ProductApi.getDatascopeList({productId: this.id, ...this.query, ...this.pagination, pageNum: this.pagination.currentPage}).then(res => {
        this.tagList = res.body.list;
        this.pagination.total = res.body.total;
      });
    },
    getDatascopeDetail (id) {
      ProductApi.getDatascopeRuleList(id).then(res =>{
        this.datascopeRuleList = res.body;
        this.dialogVisible = true;
      });
    },
    handleSaveDatascopeRule () {
      ProductApi.updateDatascopeRule({
        dataRuleUpdateReqDTOList: this.datascopeRuleList.map(t=>({
          id: t.id,
          conditionalRelation: t.conditionalRelation,
        })),
      }).then(() => {
        this.dialogVisible = false;
        this.getTableList();
      });
    },
  },
};
</script>

