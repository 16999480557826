<template>
  <div class="login" v-on:keyup.enter="handleSubmitForLogin">
    <div class="left">
      <img
        :src="require('@/assets/logo_short.png')"
        alt="logo"
        style="width: 88px"
      />
      <div class="platform">小紫医疗版本控制管理平台</div>
      <div class="company">
        <span>Copyright©2021 海南小紫医疗科技.</span>
        <div>
          <a target="_blank" href="http://beian.miit.gov.cn/"
            >琼ICP备2023003828号</a
          >
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=46010502000684"
            ><img :src="require('../assets/imgs/reg.png')" />
            <p>琼公网安备 46010502000684号</p></a
          >
        </div>
      </div>
    </div>
    <div class="right">
      <h3 style="margin-bottom: 30px">登录</h3>
      <el-tabs v-model.trim="loginType" stretch>
        <el-tab-pane label="密码登录" name="email">
          <el-form
            ref="pwdLoginForm"
            :model="loginForm"
            size="default"
            :rules="pwdLoginFormRules"
          >
            <el-form-item prop="userName">
              <el-input
                v-model.trim="loginForm.userName"
                name="userName"
                maxlength="11"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-row :gutter="10">
                <el-col :span="14">
                  <el-form-item prop="captcha">
                    <el-input
                      v-model.trim="loginForm.captcha"
                      placeholder="请输入图形验证码"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <img
                    class="valid-code"
                    :src="validImg"
                    @click="getValidImg"
                  />
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item prop="passwd">
              <el-input
                v-model.trim="loginForm.passwd"
                name="passwd"
                placeholder="请输入密码"
                type="password"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="验证码登录" name="phone">
          <el-form
            ref="phoneLoginForm"
            :model="phoneLoginForm"
            size="default"
            :rules="phoneLoginFormRules"
          >
            <el-form-item prop="userName">
              <el-input
                v-model.trim="phoneLoginForm.userName"
                maxlength="11"
                placeholder="请输入手机号"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-row :gutter="10">
                <el-col :span="16">
                  <el-form-item prop="captcha">
                    <el-input
                      v-model.trim="phoneLoginForm.captcha"
                      placeholder="请输入图形验证码"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <img class="valid-code" :src="validImg" />
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model.trim="phoneLoginForm.password"
                placeholder="请输入手机验证码"
                type="text"
              >
                <template slot="suffix">
                  <span
                    @click="getValidCode"
                    class="float-right text-primary cursor-pointer"
                    >{{ validTip }}</span
                  >
                </template>
              </el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <el-form size="default">
        <el-form-item style="text-align: left">
          <!-- <el-checkbox v-model.trim="loginForm.input3">记住账号</el-checkbox> -->
          <span class="float-right text-primary">忘记密码</span>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            class="login-button"
            @click="handleSubmitForLogin"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import AccountApi from '@/api/login';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import md5 from 'js-md5';
export default {
  name: 'Login',
  data () {
    return {
      // 登录页面数据
      loginForm: {
        userName: '',
        password: '',
        passwd: '',
        captcha: '',
        captchaKey: '',
        byType: '2',
      },
      phoneLoginForm: {
        password: '',
        captcha: '',
        captchaKey: '',
        userName: '',
        byType: '1',
      },
      validImg: '',
      loginType: 'email',
      validTip: '获取验证码',
      pwdLoginFormRules: {
        userName: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1\d{10}$/, message: '手机号不正确', trigger: 'blur' },
        ],
        captcha: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        passwd: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
      phoneLoginFormRules: {
        userName: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1\d{10}$/, message: '手机号不正确', trigger: 'blur' },
        ],
        captcha: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        password: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    ...mapActions({
      getMenuInfo: 'getMenuInfo',
    }),
    ...mapMutations({
      updateUserInfo: 'UPDATE_USERINFO',
    }),
    getValidCode () {
      if (!this.timerId) {
        this.tick = 60;
        this.timerId = setInterval(() => {
          this.tick--;
          this.validTip = `重新获取(${this.tick})`;
          if (this.tick === 0) {
            clearInterval(this.timerId);
            this.timerId = '';
            this.validTip = '获取验证码';
          }
        }, 1000);
      }
    },
    getValidImg () {
      AccountApi.getValidImg().then((res) => {
        this.validImg = res.body.codeBase;
        if (this.loginType === 'email') {
          this.loginForm.captchaKey = res.body.codeKey;
        } else {
          this.phoneLoginForm.captchaKey = res.body.codeKey;
        }
      });
    },
    handleSubmitForLogin () {
      // 验证是否满足结果
      let formType = '';
      let postData = {};
      if (this.loginType === 'email') {
        formType = 'pwdLoginForm';
        this.loginForm.password = md5(this.loginForm.passwd);
        postData = this.loginForm;
      } else {
        formType = 'phoneLoginForm';
        postData = this.phoneLoginForm;
      }

      this.$refs[formType].validate((valid) => {
        if (valid) {
          // 这里需要调用接口
          AccountApi.login(postData).then((res) => {
            this.$local.set('token', res.body.access_token);
            this.getMenuInfo().then(() => {
              this.$local.set('userInfo', res.body);
              this.updateUserInfo(res.body);
              this.$router.push(this.menuTree[0]?.url);
            });
          });
        } else {
        }
      });
    },
  },
  computed: {
    ...mapGetters([
      'menuTree',
    ]),
  },
  beforeDestroy () {
    this.timerId && clearInterval(this.timerId);
  },
  created () {
    this.getValidImg();
  },
};
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  display: flex;
  background-image: url("../assets/imgs/login_back.jpg");

  .left {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .platform {
      margin-top: 60px;
      font-weight: 500;
      font-size: 22px;
      line-height: 1.5;
      text-align: center;
      color: rgb(52, 58, 64);
    }

    .company {
      position: absolute;
      bottom: 20px;
      font-size: 13px;
      // color: rgb(33, 37, 41);
      text-align: center;
      > div {
        display: flex;
        align-items: center;
        height: 18px;
        & > * {
          display: flex;
          align-items: center;
        }
      }
      a {
        text-decoration: none;
        color: black;
        img {
          height: 14px;
        }
      }
    }
  }

  .right {
    width: 40%;
    height: 100%;
    box-shadow: rgb(245, 245, 245) -19px 0px 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .el-form {
      width: 320px;
    }

    .login-button {
      width: 100%;
    }

    .valid-code {
      height: 40px;
      line-height: 40px;
      vertical-align: top;
    }
  }
}
</style>
