<template>
  <div>
    <query-form labelWidth="110px" class="content_block" ref="queryForm" @search="handleSearch" @reset="handleReset">
      <el-form-item label="意见单编号：" >
        <el-input placeholder="请输入意见单编号" v-model.trim="query.code"></el-input>
      </el-form-item>
      <el-form-item label="客户名称：" >
        <el-input placeholder="请输入客户名称" v-model.trim="query.tenantName"></el-input>
      </el-form-item>
      <el-form-item label="提交人：" >
        <el-input placeholder="请输入提交人" v-model.trim="query.submitUserName"></el-input>
      </el-form-item>
      <el-form-item label="反馈模块：" >
        <el-select v-model="query.module" @change="handleSearch">
          <el-option v-for="i in moduleList" :key="i.value" :label="i.name" :value="i.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态：" >
        <el-select v-model="query.status" @change="handleSearch">
          <el-option v-for="i in statusList" :key="i.value" :label="i.name" :value="i.value"></el-option>
        </el-select>
      </el-form-item>
    </query-form>
    <table-with-pagination :tableData="feedbackList"
      class="content_block"
      :columnList="columnList"
      :pagination.sync="pagination"
      :sortInfo.sync="updateSortInfo"
      @size-change="getTableList"
      @current-change="getTableList"
      @sort-change="getTableList"
    />
  </div>
</template>
<script>
import { getFeedbackList } from '@/api/feedback';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import { mapGetters } from 'vuex';
export default {
  name: 'FEEDBACK_INDEX',
  mixins: [
    TablePaginationMixin,
  ],
  data () {
    return {
      moduleList: [
        {
          name: '全部',
          value: null,
        },
        {
          name: '工单中心',
          value: '1',
        },
        {
          name: '客户管理',
          value: '2',
        },
        {
          name: '设备管理',
          value: '3',
        },
        {
          name: '系统管理',
          value: '4',
        },
      ],
      statusList: [
        {
          name: '全部',
          value: null,
        },
        {
          name: '待处理',
          value: '2',
        },
        {
          name: '已处理',
          value: '3',
        },
      ],
      query: {
        code: '',
        tenantName: '',
        submitUserName: '',
        module: null,
        status: null,
      },
      feedbackList: [],
      columnList: [
        {
          tooltip: true,
          label: '意见单编号',
          minWidth: '160px',
          prop: 'code',
          fixed: 'left',
          render: (h, {row}) => {
            if (this.actionPermissionMap['saas_feedBackCenter:detail']) {
              return <span class='cursor-pointer text-primary' onClick={this.handleActionClick.bind(this, row, 'detail')}>{row.code}</span>;
            } else {
              return <span>{row.code}</span>;
            }
          },
        },
        {
          tooltip: true,
          label: '客户名称',
          minWidth: '150px',
          prop: 'tenantName',
        },
        {
          tooltip: true,
          label: '提交人',
          minWidth: '150px',
          prop: 'submitUserName',
        },
        {
          tooltip: true,
          label: '手机号',
          minWidth: '150px',
          prop: 'mobilephone',
        },
        {
          tooltip: true,
          label: '提交时间',
          minWidth: '150px',
          prop: 'submitTime',
          format: this.$options.filters['date-format'],
        },
        {
          tooltip: true,
          label: '反馈模块',
          minWidth: '150px',
          prop: 'moduleDesc',
        },
        {
          tooltip: true,
          label: '问题描述',
          minWidth: '150px',
          prop: 'remark',
        },
        {
          tooltip: true,
          label: '处理人',
          minWidth: '150px',
          prop: 'handlerUserName',
        },
        {
          tooltip: true,
          label: '工号',
          minWidth: '150px',
          prop: 'handlerUserCode',
        },
        {
          tooltip: true,
          label: '处理时间',
          minWidth: '150px',
          prop: 'handleTime',
          format: this.$options.filters['date-format'],
        },
        {
          tooltip: true,
          label: '状态',
          minWidth: '150px',
          prop: 'statusDesc',
        },
        {
          tooltip: true,
          label: '处理结果',
          minWidth: '150px',
          prop: 'handleResult',
        },
        {
          label: '操作',
          minWidth: '180px',
          fixed: 'right',
          render: (h, {row}) => {
            let actions = [];
            if (row.status === '2') {
              actions.push(<span v-auth={'saas_feedBackCenter:handle'} class='cursor-pointer text-primary margin-left' onClick={this.handleActionClick.bind(this, row, 'process')}>处理</span>);
            }
            return <div>{...actions}</div>;
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['actionPermissionMap']),
  },
  methods: {
    handleActionClick ({id}, action) {
      if (action === 'detail') {
        this.$router.push({
          name: 'FEEDBACK_DETAIL',
          query: {
            id,
          },
        });
      } else if (action === 'process') {
        this.$router.push({
          name: 'FEEDBACK_PROCESS',
          query: {
            id,
          },
        });
      }
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    handleReset () {
      this.query = {
        code: '',
        tenantName: '',
        submitUserName: '',
        module: null,
        status: null,
      };
      this.handleSearch();
    },
    getTableList () {
      getFeedbackList({...this.query, ...this.pagination, pageNum: this.pagination.currentPage }).then(res => {
        this.feedbackList = res.body.list;
        this.pagination.total = res.body.total;
      });
    },
  },
};
</script>

