import Enum from '@/utils/Enum';

const portalTypeList = [{
  value: 1,
  name: '企业',
},
{
  value: 2,
  name: '组织机构',
}];

export default new Enum(portalTypeList);
export { portalTypeList as PortalTypeList };
