<template>
  <div>
    <content-block title="维修商基本信息">
      <el-descriptions size="medium" class="content_block">
        <el-descriptions-item label="维修商名称">{{ tenatDetail.name || '--'}}</el-descriptions-item>
        <el-descriptions-item label="统一社会信用代码">{{ tenatDetail.certCode || '--'}}</el-descriptions-item>
        <el-descriptions-item label="申请人">{{ tenatDetail.applicantName || '--'}}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{ tenatDetail.applicantPhoneNumber || '--'}}</el-descriptions-item>
        <el-descriptions-item label="推广渠道">{{ tenatDetail.channelName || '--' }}</el-descriptions-item>
        <el-descriptions-item label="推荐人">{{ tenatDetail.referrerName || '--' }}</el-descriptions-item>
        <el-descriptions-item label="申请时间">{{ tenatDetail.createTime | dateFormat }}</el-descriptions-item>
      </el-descriptions>
    </content-block>
    <content-block title="医院信息">
      <table-with-pagination
        :tableData="hospitalList"
        class="content_block"
        :showRightToolbar="false"
        :filterConfig="{ remote: true }"
        :columnList="columnList"
        :pagination.sync="pagination"
        @size-change="getReportDetailTableHandle"
        @current-change="getReportDetailTableHandle"
        @filter-change="payStatusChangeHandle"
      />
      <el-button @click="$router.go(-1)">返回</el-button>
    </content-block>
  </div>
</template>
<script>
import * as dayjs from 'dayjs';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import { getwirelessReportDetail, getwirelessReportDetailTable } from '@/api/report';

export default {
  name: 'REPAIRMAN',
  mixins: [TablePaginationMixin],
  props: {
    id: {
      type: String,
    },
  },
  filters: {
    dateFormat: (val) =>{
      return dayjs(val).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  data () {
    return {
      tenatDetail: {},
      hospitalList: [],
      columnList: [
        {
          tooltip: true,
          label: '医院名称',
          minWidth: '100px',
          prop: 'customerName',
        },
        {
          tooltip: true,
          label: '支付状态',
          minWidth: '100px',
          prop: 'payStatusDesc',
          filters: [
            {label: '已支付', value: '2'},
            {label: '未支付', value: '1'},
          ],
          filterMultiple: false,
        },
        {
          tooltip: true,
          label: '支付时间',
          minWidth: '100px',
          prop: 'paymentDate',
          format: val => val ? dayjs(val).format('YYYY-MM-DD HH:mm:ss') : '-',
        },
        {
          tooltip: true,
          label: '到期时间',
          minWidth: '100px',
          prop: 'indate',
          format: val => val ? dayjs(val).format('YYYY-MM-DD HH:mm:ss') : '-',
        },
        {
          tooltip: true,
          label: '工单总数（单）',
          minWidth: '100px',
          prop: 'totalWorkOrderNum',
        },
      ],
    };
  },
  created () {
    this.getReportDetailHandle();
    this.getReportDetailTableHandle();
  },
  methods: {
    async payStatusChangeHandle ({ values }) {
      this.pagination.currentPage = 1;
      this.getReportDetailTableHandle({ payStatus: values[0] });
    },
    async getReportDetailHandle () {
      const rs = await getwirelessReportDetail({ id: this.id });
      if (rs.heads.code === 200) {
        this.tenatDetail = rs.body;
      }
    },
    async getReportDetailTableHandle (prm = {}) {
      let param = {
        id: this.id,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
      };
      param = {...param, ...prm};
      const res = await getwirelessReportDetailTable(param);
      if (res.heads.code === 200) {
        this.hospitalList = res.body?.list.map((li, ind) => ({ ...li, index: ++ind }));
        this.pagination.total = res.body.total;
      }
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getReportDetailTableHandle();
    },
  },
};
</script>
