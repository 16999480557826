import server from '../request';

const getFeedbackList = ({code, tenantName, submitUserName, module, status, pageNum, pageSize})=>{
  return server.post('/xiaozi-saas/wireless/feedback/queryPage', {code, tenantName, submitUserName, module, status, pageNum, pageSize});
};

const getFeedbackDetail = (id)=>{
  return server.post('/xiaozi-saas/wireless/feedback/queryDetail', {id});
};

const processFeedback = ({id, handleResult})=>{
  return server.post('/xiaozi-saas/wireless/feedback/handle', {id, handleResult});
};

export {
  getFeedbackList,
  getFeedbackDetail,
  processFeedback,
};
