import server from '@/request';

const getOrderList = ({code, productName, channelName, buyerId, payStatus, payMethod, invoiceStatus, startPayTime, endPayTime, startExpiryTime, endExpiryTime, pageNum, pageSize, buyerName})=>{
  return server.post('/xiaozi-saas/wireless/order/queryPage', {code, productName, channelName, buyerId, payStatus, pageNum, pageSize, payMethod, invoiceStatus, startPayTime, endPayTime, startExpiryTime, endExpiryTime, buyerName});
};

export const getDetailOrderInfo = (params) => {
  return server.post('/xiaozi-saas/wireless/order/queryDetail', params);
};

export const approvePayVoucher = (params) => {
  return server.post('/xiaozi-saas/wireless/order/approvePayVoucher', params);
};

export {
  getOrderList,
};
