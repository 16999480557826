<template>
    <div>
        <el-dialog :visible.sync="dialogVisible"
            :close-on-click-modal="false"
            :title="title"
            destroy-on-close
            width="500px"
            @closed="handleDialogColse"
        >
          <div class="content_block">
            <el-input placeholder="请输入内容" v-model.trim="keyword" size="small">
                <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
            </el-input>
            <el-table :data="searchList"
              ref="accountTableList"
              border
              show-overflow-tooltip
              size="small"
            >
                <el-table-column
                  prop="id"
                  width="55px">
                  <template slot="header">
                    <span></span>
                  </template>
                  <template  slot-scope="scope">
                     <el-checkbox v-model.trim="scope.row.checked" @change="handleSelectionChange($event,scope.row)"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column
                    label="姓名"
                    width="120px"
                    prop="realName">
                </el-table-column>
                <el-table-column
                    label="部门"
                    prop="deptNm">
                </el-table-column>
            </el-table>
            <el-tag
                v-for="tag in selected"
                :key="tag.id"
                @close="handleCloseTag(tag.id)"
                closable>
                {{tag.realName}}
            </el-tag>
          </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="handleDialogColse">取 消</el-button>
                <el-button size="small" type="primary" @click="handleConfirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import _ from 'lodash';
import AccountApi from '@/api/account';
export default {
  name: 'account-selector',
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '选择审核人员',
    },
    defaultSelected: {
      type: Array,
      default: () => {
        return [];
      },
    },
    maxCount: {
      type: Number,
      default: Infinity,
    },
  },
  data () {
    return {
      dialogVisible: this.value,
      keyword: '',
      searchList: [],
      selected: _.cloneDeep(this.defaultSelected),
    };
  },
  watch: {
    value: function (val) {
      this.dialogVisible = val;
      if (this.dialogVisible) {
        this.keyword = '';
        this.searchList = [];
        this.search();
      }
    },
    defaultSelected: function () {
      this.selected = _.cloneDeep(this.defaultSelected);
    },
  },
  methods: {
    toggleSelection (row, isChecked = false) {
      let index = this.searchList.findIndex(t => t.id === row.id);
      if (index >= 0) {
        this.$set(this.searchList, index, Object.assign(row, {checked: isChecked}));
      }
    },
    handleCloseTag (id) {
      let index = this.selected.findIndex(t => t.id === id);
      if (index >= 0) {
        this.toggleSelection(this.selected[index]);
        this.selected.splice(index, 1);
      }
    },
    handleConfirm () {
      if (!this.selected || this.selected.length < 1) {
        this.$message({
          showClose: true,
          message: '至少需要选择一个人员',
          type: 'error',
        });
        return;
      }
      this.handleDialogColse();
      this.$emit('confirm', this.selected);
    },
    handleDialogColse () {
      this.dialogVisible = false;
      this.$emit('input', false);
    },
    handleSelectionChange (val, row) {
      if (val) {
        if (this.selected.length + 1 > this.maxCount) {
          this.$message({
            showClose: true,
            message: `最大可选择人员数量为${this.maxCount}`,
            type: 'error',
          });
          this.toggleSelection(row);
        } else {
          this.selected.push(row);
        }
      } else {
        let index = this.selected.findIndex(t => t.id === row.id);
        index >= 0 && this.selected.splice(index, 1);
      }
    },
    search () {
      AccountApi.getUserList({
        userSts: 1,
        status: 2,
        pageIndex: 1,
        pageSize: 5,
        realName: this.keyword,
      }).then(data => {
        data.body.list.forEach(t => {
          t.checked = !!this.selected.find(s => s.id === t.id);
        });
        this.searchList = data.body.list;
      }, () => {
        this.searchList = [];
      });
    },
  },
};
</script>

<style scoped>
.el-table {
    margin: 16px 0;
}

.el-tag + .el-tag {
    margin-left: 10px;
}
</style>
